.page {
    .yotpo {
        // .yotpo-bottomline,
        // .yotpo-review-stars {
            .yotpo-icon-star,
            .yotpo-icon-empty-star,
            .yotpo-icon-half-star {
                color: $primary-500 !important;
            }
        // }

        .standalone-bottomline {
            .star-clickable {
                cursor: unset;
            }
        }
    }
}

.testimonials-header {
    @include font-size(36px);
    font-family: $rokkitt;
    font-weight: bold;
    margin: 30px 0;
}
