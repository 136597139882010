@import "./menu";

.site-header {
    position: sticky;
    width: 100%;
    z-index: 101;
    left: 0;
    top: 62px;

    @include media-breakpoint-up(md) {
        top: 50px;
    }


    .container-wide {
        max-width: 1740px;
    }

    &.top-banner-exist {
        @include media-breakpoint-down(sm) {
            top: 102px;
        }
    }
}

.top-header-menu {
    background-color: $primary;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 51;

    .container-wide {
        display: flex;
        min-height: 30px;
        align-items: center;
        padding: 6px 20px;
        max-width: 1740px;
        @include media-breakpoint-up(sm) {
            min-height: 36px;
        }
        @include media-breakpoint-up(md) {
            height: 50px;
            min-height: 0;
            padding: 0 20px;
        }
    }

    .left-content {
        flex: 1 1 auto;
        min-width: 0;
        display: flex;
        align-items: center;

        > .navbar {
            margin-left: -20px;
            // display: none;
            @include media-breakpoint-up(md) {
                display: block;
            }

            .nav {
                flex-wrap: nowrap;
                margin-right: 20px;
            }

            &.top-banner-exist {
                @include media-breakpoint-down(sm) {
                    margin-top: 40px;
                }
            }
        }
    }

    .right-content {
        align-items: center;
        flex: 0 0 auto;
        margin-left: 30px;
        display: none;
        @include media-breakpoint-up(lg) {
            display: flex;
        }

        .button {
            &.button-secondary {
                flex: 0 0 auto;
                min-height: 50px;
                margin: 0 -20px 0 20px;
                padding: 0 28px;
                @include font-size(12px);
                @include media-breakpoint-up(xl) {
                    margin: 0 -20px 0 33px;
                    @include font-size(14px);
                }
            }
        }
    }

    .header-links-container {
        display: flex;
        align-items: center;
        flex: 0 0 auto;

        .top-nav-link {
            @include font-size(12px);
            color: $white;
            font-weight: $font-semibold;
            text-transform: uppercase;
            text-decoration: none;
            @include media-breakpoint-up(xl) {
                @include font-size(14px);
            }

            ~ .top-nav-link {
                margin-left: 30px;
            }
        }
    }
}

.top-header-content {
    @include font-size(10px,14px);
    color: $white;
    font-weight: $font-semibold;
    flex: 1 1 auto;
    min-width: 0;
    text-align: center;
    // display: none;
    display: block;

    .top-persistant-slider {
        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40px;
            background-color: $black;
            display: flex;
            align-items: center;
        }
    }

    @include media-breakpoint-up(sm) {
        @include font-size(12px,16px);
    }

    @include media-breakpoint-up(md) {
        display: block;
    }

    @include media-breakpoint-up(lg) {
        flex: 0 1 auto;
        text-align: left;
    }

    @include media-breakpoint-up(xl) {
        @include font-size(14px,18px);
    }

    .slide {
        > span {
            color: $primary-900;
        }
    }
}

.categories-menu {
    background-color: rgba($grayscale-50, 0.5);
    backdrop-filter: blur(5px);
    @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        padding: 0 12px 0 10px;
    }

    @include media-breakpoint-down(xs) {
        &::after {
            display: block;
            flex: 1 1 auto;
            min-width: 0;
            content: '';
            order: 3;
        }
    }

    .main-mob-logo-link {
        display: none;
        margin-top: 5px;

        @include media-breakpoint-down(md) {
            display: block;
        }

        @include media-breakpoint-up(sm) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        @include media-breakpoint-down(xs) {
            order: 2;
            margin: 5px 0 0 6px;
        }

        img {
            display: block;
        }
    }

    .navbar-toggler {
        width: 48px;
        height: 48px;
        flex: 0 0 auto;
        position: relative;
        border: 0;
        border-radius: 0;
        padding: 0;
        margin: 0;
        outline: 0;
        cursor: pointer;

        @include media-breakpoint-down(xs) {
            order: 1;
        }

        > i {
            position: absolute;
            width: 18px;
            height: 2px;
            background: $primary-900;
            left: 15px;
            top: 18px;

            + i {
                top: 23px;

                + i {
                    top: 28px;
                }
            }
        }
    }
}

.categories-menu-container {
    display: flex;
    align-items: center;
    height: 100%;

    @include media-breakpoint-down(xs) {
        order: 4;
    }

    .container-wide {
        display: flex;
        height: 100%;
        @include media-breakpoint-up('lg') {
            justify-content: space-between;
            padding: 0 12px 0 20px;
        }
        @include media-breakpoint-between(lg, xl) {
            padding: 0 calculatevw(12px, 1800px) 0 calculatevw(20px, 1800px);
        }
    }
}

.categories-menu-group {
    display: flex;
    align-items: center;
}

.navbar {
    .nav-item {
        &.active {
            background-color: $red;

            &.lobstergram {
                background-color: $white;
            }
        }
    }

    .primary-cat-nav {
        margin-left: 0;
        flex-direction: row;

        @include media-breakpoint-down('sm') {
            display: flex;
            flex-wrap: wrap;

            .nav-item {
                max-width: 33.33%;

                img,
                svg,
                .lobster-logo-wrapper {
                    width: 100%;
                }
            }
        }

        .nav-item {
            &.active,
            &:hover {
                background-color: inherit;
            }

            &.seafood {
                background-color: $primary-900;
            }

            &.lobstergram {
                margin: -7px 0;
                padding-top: 8px;
                // background-color: $white;

                @include media-breakpoint-up(md) {
                    padding-top: 0;
                    margin: 3px 0 0;
                }

                .lobster-logo-wrapper {
                    width: 124px;
                    height: 40px;
                    background-color: transparent;

                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .nav-link {
                &:hover,
                &:focus {
                    background-color: inherit;
                }
            }
        }

        .nav-link {
            padding: 3px 10px 2px;
        }
    }

    .dropdown-menu {
        margin: 0;
    }
}

.header-top-nav-wrapper {
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
        padding-right: 10px;
    }

    @include media-breakpoint-up(lg) {
        padding: 0;
        justify-content: space-around;
        padding-top: 0;
        padding-bottom: 0;
    }

    .icon {
        @include media-breakpoint-between(lg, xl) {
            width: calculatevw(40px, 1800px);
        }
    }
}

.search-nav-item,
.user-nav,
.minicart {
    display: flex;
    height: 100%;
    align-items: center;

    @include media-breakpoint-up('md') {
        padding-left: 10px;
        padding-right: 10px;
    }

    @include media-breakpoint-between(lg, xl) {
        padding-left: calculatevw(10px, 1800px);
        padding-right: calculatevw(10px, 1800px);
    }

    @include media-breakpoint-up('xl') {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.user {
    position: relative;
    white-space: nowrap;

    &:hover {
        .user-menu {
            display: block;
        }
    }
}

.user-menu {
    position: absolute;
    right: 0;
    top: 100%;
    display: none;
    background: $white;
    border: 1px solid $grayscale-200;
    min-width: 180px;
}

.user-menu-list {
    margin: 0;
    padding: 16px 20px;
    list-style-type: none;

    > li {
        + li {
            margin-top: 12px;
        }
    }

    .button-link {
        text-decoration: none;
    }
}

.user-menu-name {
    display: block;
    border-bottom: 1px solid $grayscale-200;
    padding: 12px 20px;
    font-weight: $font-semibold;
    @include font-size(16px,24px);
}

.minicart-link,
.minicart-total {
    position: relative;

    &:hover {
        color: $dark;
    }

    .minicart-quantity {
        position: absolute;
        top: -10px;
        left: 24px;
        @include font-size(12px,20px);
        width: 20px;
        height: 20px;
        background: $primary-900;
        color: $white;
        border-radius: 50%;
        text-align: center;

        @include media-breakpoint-between(lg, xl) {
            top: calculatevw(-10px, 1800px);
            left: calculatevw(24px, 1800px);
            font-size: calculatevw(12px, 1800px);
            line-height: calculatevw(20px, 1800px);
            width: calculatevw(20px, 1800px);
            height: calculatevw(20px, 1800px);
        }
    }
}
