$searchInputHeight: 140px;
$headerCalculatedHeight: calculatevw(100px, 1800px);

.search-nav-item {
    .search-close-icon-img {
        display: none;
        padding: 0 11px;
    }

    &.active {
        background-color: $gray-17;

        .search-icon-img {
            display: none;
        }

        .search-close-icon-img {
            display: block;
        }
    }

    .search-icon {
        cursor: pointer;
    }
}

.search-overlay {
    position: fixed;
    left: 0;
    right: 0;
    background: $white;
    height: 100vh;
    z-index: 0;
    opacity: 0.95;
    top: calc(64px + #{$searchInputHeight});

    @include media-breakpoint-up(lg) {
        top: calc(100px + #{$searchInputHeight});
    }

    @include media-breakpoint-between(lg, xl) {
        top: calc(#{$headerCalculatedHeight} + #{$searchInputHeight});
    }
}

.site-search-form-container {
    margin-top: -300px;
    opacity: 0;
    position: fixed;
    left: 0;
    width: 100%;
    padding: 27px 0;
    background-color: $gray-17;
    transition: margin 0.3s ease, opacity 0.5s ease-in-out;
    visibility: hidden;
    top: 64px;

    @include media-breakpoint-up(lg) {
        top: 100px;
    }

    @include media-breakpoint-between(lg, xl) {
        top: calculatevw(100px, 1800px);
    }

    .site-search {
        display: none;
        height: auto;
    }

    &.active {
        margin-top: 0;
        opacity: 1;
        visibility: visible;

        .site-search {
            display: block;
        }
    }

    .search-field {
        @include font-size(32px);
        font-weight: $font-semibold;
        background-color: transparent;
        border: 0;
        padding: 5px 25px;
        color: $grayscale-900;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            text-transform: uppercase;
        }
    }

    .search-field-label {
        @include font-size(13px);
        margin: 0 0 0 25px;
        color: $grayscale-600;
        text-transform: uppercase;
    }

    .site-search {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
    }

    .suggestions {
        display: block;
        position: relative;
        border: 0;
        padding: 30px 25px 10px 25px;
        top: 27px;
        right: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: transparent;
        height: calc(100vh - 64px - #{$searchInputHeight});
    
        @include media-breakpoint-up(md) {
            padding: 40px 25px 20px 25px;
        }

        @include media-breakpoint-up(lg) {
            height: calc(100vh - 100px - #{$searchInputHeight});
        }

        @include media-breakpoint-between(lg, xl) {
            height: calc(100vh - #{$headerCalculatedHeight} - #{$searchInputHeight});
        }

        .container {
            max-width: none;
            padding: 0;
        }

        .header {
            @include font-size(16px,22px);
            font-weight: $font-medium;
            text-transform: uppercase;
            color: $grayscale-900;
            margin-bottom: 12px;

            @include media-breakpoint-up(md) {
                @include font-size(18px,24px);
                margin-bottom: 20px;
            }

            @include media-breakpoint-up(lg) {
                @include font-size(20px,26px);
            }
        }

        .list {
            margin-left: 0;
            padding-left: 0;
        }

        .item {
            &:not(.product-item) {
                padding: 0 0 10px 15px;
            }
        }

        .items {
            + .items {
                margin-top: 25px;

                @include media-breakpoint-up(md) {
                    margin-top: 35px;
                }
            }
        }

        .suggestions-content {
            @include media-breakpoint-up(md) {
                display: flex;
            }

            .suggestions-left {
                @include media-breakpoint-up(md) {
                    flex: 0 0 260px;
                    max-width: 260px;
                    margin-right: 20px;
                }

                @include media-breakpoint-up(lg) {
                    flex: 0 0 300px;
                    max-width: 300px;
                    margin-right: 30px;
                }
            }

            .suggestions-right {
                margin-top: 25px;

                @include media-breakpoint-up(md) {
                    flex: 1 1 auto;
                    min-width: 0;
                    margin: 0;
                }
            }
        }

        .suggestions-list {
            display: flex;
            margin: -10px;
            padding: 0;
            list-style-type: none;
            flex-wrap: wrap;

            .item {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 10px;
                box-sizing: border-box;

                @include media-breakpoint-up(lg) {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                }

                @include media-breakpoint-up(bxl) {
                    flex: 0 0 16.6666%;
                    max-width: 16.6666%;
                }
            }
        }
    }

    .suggestion-product-link {
        text-decoration: none;
    }

    .suggestion-tile {
        display: flex;
        flex-direction: column;

        img {
            width: 100%;
            margin-bottom: 20px;
        }

        .product-name {
            @include font-size(14px, 20px);
            margin-bottom: 10px;
            text-transform: uppercase;
            font-weight: $font-semibold;
            color: $dark;
            border: 0;

            @include media-breakpoint-up(md) {
                @include font-size(16px, 22px);
            }
        }
    }

    .doyoumean-label {
        padding-left: 30px;
    }

    .show-all-results-container {
        display: flex;
        justify-content: flex-end;
        margin: 20px;
    }

    .show-all-results-btn {
        padding: 10px 20px;
        font-weight: $font-semibold;
        text-transform: uppercase;
    }

    .suggestion-content-link,
    .category-content-link,
    .content-content-link,
    .phrase-content-link {
        @include font-size(14px, 20px);
        text-transform: uppercase;
        color: $dark;
        text-decoration: none;

        &:not(.phrases) {
            font-weight: $font-semibold;
        }

        @include media-breakpoint-up(md) {
            @include font-size(16px, 22px);
        }

        &:hover {
            color: $primary-400;
        }
    }

    .suggestion-product-link {
        &:hover {
            .product-name {
                color: $primary-400;
            }
        }
    }

    .input-row {
        display: flex;
        align-items: center;
    }

    .search-icon-go {
        width: 50px;
    }

    .submit-site-search-btn {
        border: 0;
        background: none;
        appearance: none;
        margin-right: 25px;
    }
}

.results-search-form-container {
    border-top: 1px solid $grayscale-400;
    border-bottom: 1px solid $grayscale-400;
    padding: 10px 0 30px;
    text-align: left;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
    }

    .search-wrapper {
        width: 100%;
        max-width: 450px;
    }

    .search-overlay,
    .search-field-label {
        display: none;
    }

    .search-field {
        border-width: 1px;
        margin-right: 5px;
    }

    .input-row {
        display: flex;
        margin-top: 5px;
    }

    .submit-site-search-btn {
        height: 50px;
        border: 0;
    }

    .search-field {
        height: 50px;
    }
}
