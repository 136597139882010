.top-persistant-slider {
    &:not(.slick-initialized) {
        display: flex;
        overflow: hidden;

        .slide {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
}
