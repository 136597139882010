html {
    height: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: 100px;

    @include media-breakpoint-between(lg, xl) {
        scroll-padding-top: calculatevw(140px, 1800px);
    }

    @include media-breakpoint-up(xxl) {
        scroll-padding-top: 140px;
    }
}

body {
    @include font-size(16px, 24px);
    font-family: $base-font-family;
    background: none repeat scroll 0 0 $body-bg;
    letter-spacing: $body-letter-spacing;
    min-height: 100%;
}

img {
    max-width: 100vw;
    border: 0;
}

img[alt] {
    @include font-size(10px, 14px);
}

// Link Classes
//---------------------------------------

a,
.link {
    color: $link-color;
    text-decoration: $link-decor;

    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decor;
    }

    &.disabled {
        cursor: not-allowed;
        color: $link-grayed-out-color;
    }
}

.link-alt {
    color: $link-alt-color;
    text-decoration: $link-alt-decor;
    text-transform: capitalize;

    &:hover {
        color: $link-alt-hover-color;
        text-decoration: $link-alt-hover-decor;
    }
}

hr {
    margin: 30px 0;
    height: 1px;
    background: $gray-4;
    color: $gray-4;
    border: 0;
    @include clearMargins;

    @include media-breakpoint-up(md) {
        margin: 40px 0;
    }
}

ul,
ol {
    padding: 0;
    margin: 0 0 0 15px;
    @include font-size(12px, 18px);
}

ul {
    list-style: none;
}

fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

td {
    padding: 1em;
    vertical-align: top;
}

*:focus {
    outline: $outline;
}

p {
    @include font-size(16px, 24px);
    font-family: $secondary-font-family;
    margin: 20px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

input[type="text"],
.imput-text {
    border-radius: 0;
}

a.anchor {
    display: block;
    @include visually-hidden;
    position: relative;
    top: -84px;

    @include media-breakpoint-up(lg) {
        top: -120px;
    }

    @include media-breakpoint-between(lg, xl) {
        top: calculatevw(-120px, 1800px);
    }
}

body > .add-to-cart-messages {
    display: none;
}

// Inner page content width
.content-width {
    @include content-width();
}

// Clearfix
.clearfix {
    @include clearfix();
}

// Visually Hidden Elements
.visually-hidden {
    @include visually-hidden;
}

.container-max {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.container-wide {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
}

.hero {
    .page & {
        padding: 20px;
        min-height: 18vw;
        height: auto;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include headerNegativeMargin;

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }

        @include media-breakpoint-up(lg) {
            padding: 100px 20px 20px;
        }

        @include media-breakpoint-between(lg, xl) {
            padding-top: calculatevw(100px, 1800px);
        }

        h1 {
            &.page-title {
                top: 0;
                margin: 0;
                left: 0;
            }
        }
    }

    .hero-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.hidden-desktop {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.hidden-mobile {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.maincontent {
    padding: 14px 0 50px;

    @include media-breakpoint-up(md) {
        padding: 30px 0 80px;
    }

    @include media-breakpoint-between(lg, xl) {
        padding: calculatevw(30px, 1800px) 0 80px;
    }

    [data-action="Sites-dartagnan-Site"] & {
        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
    }
}

.page-block {
    + .page-block {
        margin-top: 30px;
        @include media-breakpoint-up(md) {
            margin-top: 40px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 50px;
        }
    }
}

.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -10px;
    padding-bottom: 30px;
    @include media-breakpoint-up(md) {
        padding-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 50px;
    }

    .page-header-box {
        flex: 0 1 auto;
        margin: 10px;

        > h2 {
            margin: 0;
        }
    }
}

.text-center {
    text-align: center;
}
