.share-tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    .recep-title & {
        margin: 20px 0;
    }

    li {
        flex: 0 0 auto;

        a {
            display: block;

            &:hover {
                svg {
                    fill: $primary-400;
                }
            }

            svg {
                display: block;
                width: 32px;
                fill: $dark-3;
                transition: fill $hover;
            }
        }
    }
}
