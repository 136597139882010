@import "base/product/detail";
@import "../variables";
@import "../components/functions";
@import "../components/mixins";
@import "../components/powerreviews";
@import "../checkout/components/datepicker";
@import "./lgDetail";

.product-detail {
    &.product-set-detail {
        .bundle-footer {
            .prices-add-to-cart-actions {
                position: static;
                margin: 0;
            }
        }

        .prices-add-to-cart-actions {
            position: static;
            margin-top: 30px;
        }
    }

    .mobile-fixed-add-all-to-cart {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 3;

        @include media-breakpoint-up (lg) {
            display: none;
        }
    }

    h1.product-name {
        @include font-size(22px);
        font-weight: $font-semibold;
        color: $black;
        text-transform: uppercase;
        margin: 0;
        border: 0;

        @include media-breakpoint-up(lg) {
            @include font-size(26px);
        }
    }

    .attributes {
        padding: 0;
    }

    .prices {
        padding: 18px 0 0;
        display: flex;

        .price-label {
            @include font-size(18px);
            font-weight: $font-regular;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $grayscale-600;

            @include media-breakpoint-up(lg) {
                @include font-size(20px);
            }
        }

        .price {
            @include font-size(18px);
            font-weight: $font-bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $grayscale-600;

            @include media-breakpoint-up(lg) {
                @include font-size(20px);
            }
        }
    }

    .promotions {
        @include font-size(12px, 16px);
        margin: 15px 0 0;
        height: auto;
        font-weight: $font-semibold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        text-align: left;
        color: $primary-500;
    }

    .product-sku {
        display: none;
    }

    div.availability {
        margin: 32px 0 0;

        .availability-col {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;

            .non-input-label {
                @include font-size(16px);
                margin: 0;
                text-transform: uppercase;
                font-weight: $font-regular;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $primary-900;
            }

            .availability-msg {
                @include font-size(16px);
                font-weight: $font-semibold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $primary-900;
                margin: 0 0 0 5px;
            }
        }
    }

    .primary-images {
        padding: 0;

        @include media-breakpoint-up (lg) {
            padding: 0 15px;
        }
    }

    .pdp-slider-wrap {
        display: flex;
        justify-content: space-between;

        img {
            width: 100%;
        }

        .pdp-slider-nav {
            width: 17%;
            display: none;

            @include media-breakpoint-up (lg) {
                display: block;
            }

            img {
                /* stylelint-disable */
                aspect-ratio: 1/1;
                /* stylelint-enable */
                object-fit: cover;
            }

            .pdp-slider-nav-item {
                vertical-align: top;
                /* stylelint-disable */
                aspect-ratio: 1/1;
                /* stylelint-enable */
                margin-bottom: 15px;

                &.video {
                    position: relative;

                    .play {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 0 10px 20px;
                        border-color: transparent transparent transparent #fff;
                    }
                }

                &.video-src {
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        z-index: 1;
                    }
                }

                .preview-video {
                    width: 100%;
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .slick-slide {
                cursor: pointer;
                margin-bottom: 15px;

                .pdp-slider-nav-item {
                    margin: 0;
                }
            }

            .slick-current {
                .pdp-slider-nav-item {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        border: solid 2px $primary-500;
                    }
                }
            }

            .slick-track {
                transform: translate3d(0, 0, 0) !important;
            }
        }

        .pdp-slider {
            width: 100%;
            margin-bottom: 30px;

            @include media-breakpoint-up (lg) {
                width: 80%;
                margin: 0;
            }

            &:not(.slick-initialized) {
                display: flex;
                overflow: hidden;
                margin-bottom: 50px;

                @include media-breakpoint-up (lg) {
                    margin: 0;
                }

                .pdp-slider-item {
                    flex: 1 0 100%;
                    max-width: 100%;
                }
            }

            img {
                /* stylelint-disable */
                aspect-ratio: 1/1;
                /* stylelint-enable */
                object-fit: cover;
            }

            .pdp-slider-item {
                vertical-align: top;

                @include media-breakpoint-up(lg) {
                    padding: 0 1px;
                }

                iframe {
                    border: none;
                    height: 300px;
                }

                .pdp-video-src {
                    width: 100%;
                }
            }
        }
    }

    .prices-add-to-cart-actions {
        padding: 0;
        margin: 0;
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 3;

        @include media-breakpoint-up (lg) {
            position: static;
            margin-top: 30px;
        }

        .simple-quantity {
            margin: 0;
            width: 38%;

            .quantity-input-wrap {
                position: relative;
                height: 56px;
                display: flex;
                align-items: center;
                background: $white;
                border: solid 4px $gray-4;
                border-right: none;

                &.quantity-product-swap {
                    border-right: solid 4px $gray-4;
                }

                .quantity-plus,
                .quantity-minus {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background-color: $gray-10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: absolute;
                    z-index: 1;
                }

                .quantity-plus {
                    left: 20px;
                }

                .quantity-minus {
                    right: 20px;
                }
            }

            input {
                @include font-size(16px);
                height: 48px;
                width: 36px;
                -webkit-appearance: none;
                border-radius: 0;
                background: $white;
                border: none;
                text-align: center;
                font-weight: $font-semibold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $dark-4;
                padding: 0;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .cart-and-ipay {
            width: 62%;
        }
    }

    .add-to-cart,
    .add-to-cart-global {
        @include font-size(18px, 22px);
        width: 100%;
        height: 56px;
        background-color: $primary-900;
        border-color: $primary-900;
        border-radius: 0;
        font-weight: $font-semibold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        text-transform: uppercase;
    }

    .attribute {
        margin-top: 18px;
    }

    .attribute-select {
        @include font-size(16px);
        border: solid 1px $primary-900;
        position: relative;
        font-weight: $font-medium;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $primary-900;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 18px 18px 45px;
        margin-bottom: 10px;
        cursor: pointer;

        @include media-breakpoint-up (lg) {
            @include font-size(18px);
        }

        &:last-child {
            margin-bottom: 0;
        }

        .price-variation {
            margin-left: 10px;
            color: $grey7;

            > span {
                display: flex;
                flex-direction: column;
            }

            .price-label {
                display: none;
            }

            .strike-through {
                text-decoration: line-through;
                color: $grayscale-600;
            }

            .starting,
            .range,
            .sales {
                font-weight: bold;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            border: 2px solid $primary-900;
            border-radius: 50%;
            left: 15px;
        }

        &::before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            left: 19px;
            background: $primary-900;
            opacity: 0;
        }

        &.selected {
            &::before {
                opacity: 1;
            }
        }

        &.disabled,
        &.addon-disabled {
            border-color: $grayscale-300;
            color: $grayscale-600;
            pointer-events: none;

            .addon-message {
                margin-top: 5px;
            }

            .addon-message,
            .price-variation {
                color: $grayscale-600;
            }

            &::after {
                border-color: $grayscale-300;
            }

            &::before {
                background: $grayscale-300;
            }
        }

        &.addon-disabled {
            .addon-message {
                display: block;
            }
        }

        .variant-type {
            text-transform: capitalize;
        }
    }

    .stickyiosubscription {
        &.attribute {
            margin-top: 30px;
            padding-bottom: 10px;

            @include media-breakpoint-up (lg) {
                padding: 0;
            }
        }

        > label {
            @include font-size(16px, 20px);
            max-width: 100%;
            margin-bottom: 20px;
            font-weight: 600;
        }

        ul {
            li {
                margin: 0;

                + li {
                    margin-top: 24px;

                    @include media-breakpoint-up (lg) {
                        margin-top: 30px;
                    }
                }

                label {
                    ~ p {
                        margin-top: 10px;
                    }

                    ~ select {
                        margin-top: 12px;
                    }
                }

                p {
                    @include font-size(14px, 18px);
                }
            }
        }

        .stickyiosubscriptionresponse {
            margin-top: 20px;

            &.stickyiosubmansuccess {
                background-color: $success-400;
            }
        }

        .subscriptionselect {
            position: absolute;
            left: -9999px;

            &:checked {
                + label {
                    color: $primary-900;

                    &::after {
                        opacity: 1;
                    }

                    &::before {
                        border-color: $primary-900;
                    }
                }
            }

            + label {
                display: inline-block;
                vertical-align: top;
                max-width: 100%;
                box-sizing: border-box;
                cursor: pointer;
                overflow: hidden;
                margin: 0;
                min-height: 20px;
                padding-left: 32px;
                position: relative;
                @include font-size(14px,20px);
                color: $grayscale-600;
                transition: color $hover;

                &::after {
                    background: $primary-900;
                    width: 10px;
                    height: 10px;
                    box-sizing: border-box;
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    content: '';
                    z-index: 2;
                    opacity: 0;
                    transition: opacity $hover;
                    border-radius: 50%;
                }

                &::before {
                    background: $white;
                    border: 2px solid $grayscale-300;
                    width: 20px;
                    height: 20px;
                    box-sizing: border-box;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    z-index: 1;
                    transition: border-color $hover;
                    border-radius: 50%;
                }
            }
        }
    }
}

.product-breadcrumb {
    margin-bottom: 14px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
    }

    .breadcrumb {
        border: none;
        margin: 0;
    }
}

.details-tabs {
    margin-top: 50px;

    @include media-breakpoint-up(lg) {
        margin-top: 60px;
    }

    .details-tab-header-mobile {
        @include media-breakpoint-up(lg) {
            display: none;
        }

        &:first-child {
            a {
                border-top: 1px solid $gray-11;
            }
        }

        a {
            @include font-size(20px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: $font-medium;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $grayscale-600;
            text-decoration: none;
            text-transform: uppercase;
            padding: 24px 0;
            border-bottom: 1px solid $gray-11;

            &.selected {
                border-bottom: none;
                color: $dark-5;

                .plus {
                    display: none;
                }

                .minus {
                    display: block;
                }
            }

            .plus {
                display: block;
            }

            .minus {
                display: none;
            }
        }
    }

    .details-tab-headers {
        display: none;
        border-bottom: 2px solid $grayscale-400;

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        a {
            @include font-size(20px);
            width: 33.33333%;
            display: block;
            padding: 13px 0;
            font-weight: $font-medium;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $grayscale-600 !important;
            text-decoration: none;
            text-transform: uppercase;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 35px;
                right: 35px;
                bottom: -2px;
                height: 4px;
                background-color: $grayscale-800;
                opacity: 0;
            }

            &.selected {
                color: $dark-5 !important;
                font-weight: $font-semibold;

                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .details-tab-contents {
        @include media-breakpoint-up(lg) {
            padding: 34px 0;
        }

        .tab-content {
            border-bottom: 1px solid $gray-11;
            display: none;
            padding-bottom: 20px;

            @include media-breakpoint-up(lg) {
                border: none;
                padding: 0;
            }

            &.selected {
                display: block;
            }

            .tab-styles {
                p,
                ul,
                ol,
                div {
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                *,
                & {
                    font-family: $serif;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: justify;
                    color: $grayscale-900;
                    @include font-size(16px, 24px);

                    @include media-breakpoint-up(lg) {
                        @include font-size(16px, 30px);
                    }
                }

                i {
                    font-style: italic;
                }

                p,
                .custom-color {
                    * {
                        color: inherit;
                    }
                }

                ul {
                    list-style: disc;
                }

                &.nutritions {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.pdp-recipies-articles {
    background-color: $grayscale-100;
    margin-top: 50px;
    padding: 0 15px 60px;

    @include media-breakpoint-up(lg) {
        padding: 0 20px 70px;
    }

    .title-wrap {
        position: relative;
        z-index: 2;

        h4 {
            @include font-size(30px);
            text-transform: uppercase;
            font-weight: $font-semibold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $grayscale-50;
            margin-top: -150px;

            @include media-breakpoint-up(lg) {
                margin-top: -107px;
            }

            &.no-video {
                margin-top: 0;
                padding-top: 50px;
                color: $black;
            }
        }
    }

    .top-image-pdp-recipies-articles {
        position: relative;
        z-index: 1;

        .bg {
            position: relative;
            padding-bottom: 121px;
            background-color: $black;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            height: 400px;

            @include media-breakpoint-up(lg) {
                height: 624px;
                padding-bottom: 82px;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 121px;
                height: 153px;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $black 88%);

                @include media-breakpoint-up(lg) {
                    bottom: 82px;
                    height: 133px;
                }
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .pdp-recipies-articles-slider {
        position: relative;
        z-index: 2;
        margin-top: 20px;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;

        @include media-breakpoint-up(lg) {
            margin-top: 32px;
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
        }

        .slick-slide {
            width: 280px;
            margin: 0 12px;
        }

        .slick-list {
            margin: 0 -12px;
        }

        .pdp-recipies-articles-slider-item {
            display: block;

            a {
                text-decoration: none;
            }

            .recipies-articles-img {
                img {
                    display: block;
                    width: 100%;
                }
            }

            .category {
                @include font-size(16px);
                margin: 13px 0 10px;
                font-weight: $font-regular;
                font-stretch: normal;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: $dark-2;
            }

            .name {
                @include font-size(18px, 26px);
                text-transform: uppercase;
                font-weight: $font-semibold;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: $black;
            }
        }
    }

    .slick-arrow {
        display: block;
        z-index: 3;
        top: 33%;
    }

    .slick-dots {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: -9999px;
            z-index: -1;
            max-width: 100vw;
        }
    }

    .slick-prev {
        left: -30px;

        @include media-breakpoint-down(md) {
            position: absolute;
            left: -9999px;
            z-index: -1;
            max-width: 100vw;
        }
    }

    .slick-next {
        right: -30px;

        @include media-breakpoint-down(md) {
            position: absolute;
            left: -9999px;
            z-index: -1;
            max-width: 100vw;
        }
    }
}

.pdp-recommendations {
    overflow: hidden;

    .content {
        @include media-breakpoint-up(lg) {
            padding: 0 30px;
        }
    }

    .slick-dotted {
        &.slick-slider {
            margin: 0;
        }
    }

    .js-recommendation-slider-item {
        vertical-align: top;
    }

    .js-recommendation-slider {
        &:not(.slick-initialized) {
            display: flex;
            overflow: hidden;
            margin: 0 -10px;

            .js-recommendation-slider-item {
                flex: 1 0 100%;
                max-width: 100%;
                padding: 0 10px;

                @include media-breakpoint-up(sm) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @include media-breakpoint-up(md) {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                }
            }
        }
    }

    .slick-list {
        margin: 0 -10px;
    }

    .slick-slide {
        margin: 0 10px;
    }

    .slick-dots {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: -9999px;
            z-index: -1;
            max-width: 100vw;
        }
    }

    .slick-arrow {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .slick-prev {
        left: -30px;
        top: 45%;
    }

    .slick-next {
        right: -30px;
        top: 45%;
    }

    .p-w-r {
        .pr-snippet {
            .pr-snippet-stars-png {
                display: flex;
                align-items: center;
            }
        }
    }

    .recommendations {
        margin-top: 50px;
        position: relative;

        .pdp-recommendations-no-title & {
            margin-top: 30px;
        }

        .title-slot {
            @include font-size(32px);
            font-weight: $font-semibold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $black;
            text-transform: uppercase;
            position: relative;
            margin: 0 0 17px;
            padding: 0;

            @include media-breakpoint-up(lg) {
                @include font-size(24px);
                margin: 0 0 30px;
            }

            .pdp-recommendations-no-title & {
                display: none;
            }

            span {
                @include media-breakpoint-up(lg) {
                    background: $white;
                    padding: 0 25px;
                    position: relative;
                    z-index: 2;
                }
            }

            &::after {
                @include media-breakpoint-up(lg) {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background-color: $black;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: -1;
                }
            }
        }

        .product-tile {
            margin: 0;

            @include media-breakpoint-up(bxl) {
                display: flex;
            }

            .image-container {
                @include media-breakpoint-up(bxl) {
                    width: 140px;
                    flex-shrink: 0;
                    margin-right: 20px;
                    margin-bottom: 0;
                }
            }

            .price {
                @include media-breakpoint-up(bxl) {
                    @include font-size(16px);
                }
            }
        }
    }
}

[data-pp-message] {
    margin-top: 20px;
}

// product sets
.set-contains {
    text-transform: uppercase;
    text-align: center;
}

.product-detail.set-item {
    margin-top: 50px;

    h3.product-name {
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;

        a {
            text-decoration: none;
        }
    }
}

.bundle-footer {
    padding: 20px 0 50px;
    text-align: center;

    .prices-add-to-cart-actions {
        display: block;

        .cart-and-ipay {
            width: auto;
        }
    }

    .price {
        width: 100%;
        margin-bottom: 15px;
    }

    .add-to-cart-global {
        max-width: 280px;
    }
}

.frozenMessage {
    color: $danger-600;
    margin-top: 10px;
    display: none;
}

.set-items-small {
    margin-top: 30px;

    h5 {
        text-align: left;
        margin-bottom: 20px;
    }

    .set-item-small > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .product-set-image-small {
        width: 25%;

        img,
        picture {
            display: block;
            width: 100%;
        }
    }

    .set-item-details {
        width: 70%;

        .product-name {
            a {
                font-weight: bold;
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }
}

.gift-form-wrapper {
    margin-top: 20px;
}

.gift-option {
    font-weight: bold;
}

.pay-as-you-go-message {
    @include font-size(14px);
    text-align: right;
}

.add-to-cart-error {
    color: $danger-600;
    margin-top: 10px;
}

.addon-message {
    @include font-size(14px);
    color: $primary-900;
    display: none;
}

.product-badges {
    .badge-item {
        width: calc(50% - 20px);
        margin: 0 10px;
        text-align: center;
        @include font-size(12px);

        @include media-breakpoint-up(md) {
            width: calc(12.5% - 20px);
        }

        img {
            width: 100%;
        }
    }

    .badges-wrapper {
        display: flex;
        flex-wrap: wrap;

        &.badges-3,
        &.badges-5,
        &.badges-6,
        &.badges-7,
        &.badges-8 {
            .badge-item {
                @include media-breakpoint-down(sm) {
                    width: calc(33.33% - 20px);
                }
            }
        }
    }

    .badge-title {
        margin-top: -10px;
    }
}

.product-details-wrapper {
    &.ab-hide {
        .product-name,
        .yotpo-widget-instance {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    &.ab-show {
        padding-bottom: 18px;
    }
}
