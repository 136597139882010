.form-button {
    padding-top: 12px;
}

.form-group {
    margin-bottom: 24px;

    &.form-group-last {
        margin-bottom: 0;

        & + &.form-group-last {
            margin-top: 24px;
        }
    }

    &.required {
        .form-control-label {
            &::before {
                content: '*';
                color: $primary-500;
            }
        }
    }

    .form-control-label {
        @include font-size(14px,18px);
        margin-bottom: 10px;
        display: block;
    }
}

.form-pass-reset {
    margin-top: 16px;
    @include font-size(14px,18px);

    + p {
        margin-top: 16px;
    }

    > a {
        color: $primary-500;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.form-privacy-policy {
    @include font-size(12px,16px);
    text-align: center;
    margin-top: 24px;

    > a {
        color: $primary-500;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.form-row {
    margin-left: -10px;
    margin-right: -10px;
    display: block;
    @include media-breakpoint-up(sm) {
        display: flex;
    }

    &.form-row-last {
        + .form-row-last {
            margin-top: 24px;
        }

        .form-group {
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    .form-group {
        margin-left: 10px;
        margin-right: 10px;
        flex: 1;
    }
}

.invalid-feedback,
.invalid-feedback-checkout {
    @include font-size(12px, 16px);
    margin-top: 10px;
    color: $primary-500;
}
