input.textfield {
    height: 56px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.textfield-micro {
        height: 36px;
    }

    &.textfield-small {
        height: 44px;
    }
}

textarea.textfield {
    resize: none;
    overflow: auto;
    height: 200px;

    &.textfield-micro {
        height: 80px;
    }

    &.textfield-small {
        height: 120px;
    }
}

.textfield {
    background: $white;
    border: 3px solid $gray-4;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin: 0;
    outline: 0;
    padding: 13px 15px;
    @include font-size(20px,24px);
    font-family: $base-font-family;
    font-weight: $font-medium;
    color: $primary-900;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    @include placeholder($gray-3);
    transition: border-color $hover;

    &:focus {
        border-color: $grayscale-600;
    }

    &.form-control {
        &:focus {
            background: $white;
            outline: 0;
            box-shadow: none;
        }
    }

    &.is-invalid {
        border-color: $primary-500;

        &.form-control {
            padding: 13px 15px;
        }
    }

    &.textfield-micro {
        padding: 7px 10px;
        border-width: 1px;
        @include font-size(14px, 18px);

        &.is-invalid {
            &.form-control {
                padding: 7px 10px;
            }
        }
    }

    &.textfield-small {
        @include font-size(16px, 20px);
        padding: 10px 12px;
        border-width: 2px;

        &.is-invalid {
            &.form-control {
                padding: 10px 12px;
            }
        }
    }
}

.textfield-counter-value {
    text-align: right;
    @include font-size(14px, 18px);
    margin-top: 10px;
    color: $gray-8;
    display: block;

    > span {
        color: $primary-400;
    }
}
