/* stylelint-disable */
/* lora-regular - latin */
@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lora-v26-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lora-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lora-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lora-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lora-v26-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lora-v26-latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-500 - latin */
@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/lora-v26-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lora-v26-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lora-v26-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lora-v26-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lora-v26-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lora-v26-latin-500.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-700 - latin */
@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lora-v26-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lora-v26-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lora-v26-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lora-v26-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lora-v26-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lora-v26-latin-700.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-italic - latin */
@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/lora-v26-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lora-v26-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lora-v26-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lora-v26-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lora-v26-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lora-v26-latin-italic.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-500italic - latin */
@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/lora-v26-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lora-v26-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lora-v26-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lora-v26-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lora-v26-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lora-v26-latin-500italic.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-700italic - latin */
@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/lora-v26-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lora-v26-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lora-v26-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lora-v26-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lora-v26-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lora-v26-latin-700italic.svg#Lora') format('svg'); /* Legacy iOS */
}
