@import "quickView";

.product-tile {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }

    .pdp-link {
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            height: 44px;
        }

        a {
            @include font-size(16px, 22px);
            font-weight: $font-semibold;
            text-transform: uppercase;
            text-decoration: none;

            @include media-breakpoint-up(md) {
                @include font-size(18px, 24px);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }

    .tile-image {
        width: 100%;
    }

    .price {
        @include font-size(16px);
        margin-bottom: 12px;
        color: $grayscale-600;

        .price-label,
        .value {
            font-weight: $font-bold;
        }
    }

    .image-container {
        position: relative;
        margin-bottom: 20px;
        /* stylelint-disable-next-line */
        aspect-ratio: 1 / 1;

        img {
            display: block;
            /* stylelint-disable-next-line */
            aspect-ratio: 1 / 1;
        }

        .quickview,
        .tile-buy-now-quickview {
            @include font-size(16px);
            border: 0 none;
            padding: 9px 16px;
            position: absolute;
            right: 0;
            bottom: 0;
            background: $primary-900;
            color: $white;
            font-weight: $font-bold;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: $grayscale-600;
            }
        }
    }

    .product-badge-image {
        position: absolute;
        top: 7px;
        left: 7px;

        img {
            max-width: 100px;
            max-height: 50px;
            /* stylelint-disable-next-line */
            aspect-ratio: auto;

            @include media-breakpoint-up(lg) {
                max-width: 120px;
                max-height: 70px;
            }
        }
    }

    .product-badge {
        position: absolute;
        top: 7px;
        left: 7px;
        background-color: $white;
        color: $dark-3;
        display: flex;
        padding: 7px 11px 6px;
        justify-content: center;
        align-items: center;
        font-weight: $font-semibold;
        min-height: 30px;
        max-width: calc(100% - 14px);
        line-height: 1;

        @include media-breakpoint-up(lg) {
            padding: 7px 21px 6px;
        }
    }

    .tile-justfacts {
        display: none;
        color: $dark-3;

        ul {
            margin: 10px 0;
            list-style: disc;
            list-style-position: inside;
        }

        li {
            margin-bottom: 5px;
        }
    }

    .out-of-stock-msg {
        color: $primary-600;
        font-weight: $font-bold;
    }

    &.gift-cert-tile {
        .quickview,
        .price {
            display: none;
        }
    }

    &.disable-quickview {
        .quickview {
            display: none;
        }
    }

    .yotpo-display-wrapper {
        .text-m {
            display: none;
        }
    }

    .tile-buy-now {
        margin-top: 10px;
        width: 100%;
    }
}

.pdp-recommendations {
    .product-tile {
        .pdp-link {
            height: auto;
        }
    }

    .slick-slider {
        .slick-track {
            display: flex;
            align-items: stretch;
        }

        .slick-slide {
            float: none;
            height: auto;
        }

        .slick-slide {
            &,
            > div,
            .js-recommendation-slider-item,
            .product {
                flex: 1 1 auto;
                min-width: 0;
                display: flex;
                align-items: stretch;
            }

            .js-recommendation-slider-item {
                display: flex !important;
            }

            .product-tile {
                display: flex;
                align-items: stretch;
                flex-direction: column;
                flex: 1 0 100%;
                max-width: 100%;

                @include media-breakpoint-up(bxl) {
                    flex-direction: row;
                }

                .tile-body {
                    flex: 1 1 auto;
                    min-width: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    .image-container {
                        flex: 0 0 auto;
                    }

                    .pdp-link {
                        flex: 1 1 auto;
                        min-width: 0;

                        a {
                            @include media-breakpoint-up(md) {
                                display: block;
                                -webkit-line-clamp: none;
                                overflow: visible;
                            }
                        }
                    }
                }
            }
        }
    }
}
