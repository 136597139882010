@import "base/components/collapsibleItem";
@import "base/components/toastMessage";

footer {
    background-color: $white;
}

.footer-primary {
    .container-wide {
        padding: 30px 20px 0;

        @include media-breakpoint-up('lg') {
            padding: 40px 20px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
        }

        @include media-breakpoint-up('xl') {
            padding: 35px 30px 60px;
        }
    }

    .menu-footer {
        margin: 0 0 15px 10px;

        @include media-breakpoint-up('md') {
            margin: 0;
        }
    }
}

.footer-content {
    @include media-breakpoint-up('lg') {
        border-right: 1px solid $grayscale-400;
        flex: 1 1 auto;
        min-width: 0;
    }

    .footer-content-container {
        padding: 0;

        @include media-breakpoint-up('md') {
            padding: 30px 15px 30px 0;
        }

        @include media-breakpoint-up('lg') {
            padding: 33px 15px 41px 0;
        }
    }

    a {
        @include font-size(16px, 32px);
        font-weight: $font-medium;
        font-family: $secondary-font-family;
        text-decoration: none;
        color: $dark-2;

        &:hover {
            color: $link-hover-color;
        }
    }

    .footer-item {
        .title {
            @include font-size(16px);
            margin-bottom: 16px;
            font-weight: $font-bold;
            text-transform: uppercase;
            color: $dark-3;
        }

        .footer-item-btn {
            &.title {
                @include font-size(16px, 24px);
                margin-bottom: 0;
                border-top: 2px solid $gray-4;
                padding: 17px 10px;
                border-radius: 0;

                &::after {
                    @include font-size(24px, 24px);
                    display: inline-block;
                    content: '\002B';
                    margin-top: 0;
                    font-family: inherit;
                    font-weight: $font-medium;
                }
            }
        }

        &.active {
            .footer-item-btn {
                &.title {
                    &::after {
                        display: inline-block;
                        content: '\002B';
                        content: '\2212';
                    }
                }
            }
        }
    }
}

.footer-actions {
    @include media-breakpoint-up('md') {
        max-width: 555px;
        margin: 0 auto;
        padding: 0;
        flex: 0 0 555px;
    }

    @include media-breakpoint-up('lg') {
        padding: 0 10px 0 30px;
        max-width: 420px;
        flex: 0 0 420px;
    }

    @include media-breakpoint-up('xl') {
        padding: 0 55px;
        max-width: 555px;
        flex: 0 0 555px;
    }

    .truevault-polaris-privacy-notice {
        text-align: center;
    }
}

.footer-social-links {
    margin: -10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 52px;

    @include media-breakpoint-up('lg') {
        padding: 0;
        margin: -16px;
    }

    > li {
        flex: 0 0 auto;
        margin: 10px;

        @include media-breakpoint-up('lg') {
            margin: 16px;
        }
    }
}

.footer-social {
    .copy {
        @include font-size(16px);
        margin-bottom: 24px;
        font-weight: $font-semibold;
        text-transform: uppercase;
        text-align: center;
        color: $dark-3;
    }
}

.footer-links-list {
    margin-left: 0;
    padding: 10px 0;
    text-align: center;

    @include media-breakpoint-up('md') {
        text-align: left;
    }

    @include media-breakpoint-up('xl') {
        margin-left: 10px;
        padding: 0;
        display: flex;
    }

    li {
        padding: 0;
        display: inline-block;

        @include media-breakpoint-up('xl') {
            padding: 11px 0;
        }

        &:last-child {
            a {
                &::after {
                    content: none;
                }
            }
        }
    }

    a {
        @include font-size(12px, 36px);
        font-weight: $font-semibold;
        color: $grayscale-900;
        text-decoration: none;

        &::after {
            content: '|';
            display: inline-block;
            margin: 0 5px;

            @include media-breakpoint-up('xl') {
                content: none;
            }
        }

        @include media-breakpoint-up('xl') {
            @include font-size(16px, 27px);
            margin-right: 30px;
            font-weight: $font-bold;
            color: $grayscale-800;
            text-transform: uppercase;
            text-decoration: underline;
            /* stylelint-disable-next-line */
            text-underline-offset: 2px;
        }
    }
}

.footer-secondary {
    background-color: $grayscale-100;

    .container-wide {
        @include media-breakpoint-up('md') {
            padding: 7px 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .footer-links {
        padding: 0 20px;
        background-color: $grayscale-100;

        @include media-breakpoint-up('md') {
            padding: 0;
            background-color: inherit;
        }
    }
}

.footer-copy {
    @include font-size(16px);
    padding: 15px 0;
    font-family: $serif;
    text-align: center;
    color: $grayscale-900;
    background: $white;

    @include media-breakpoint-up('md') {
        text-align: right;
        background: none;
        color: $grayscale-800;
    }

    @include media-breakpoint-only('md') {
        max-width: 240px;
        margin-left: 30px;
    }

    @include media-breakpoint-up('lg') {
        padding: 0;
        display: flex;
        align-items: center;
    }

    @include media-breakpoint-only('lg') {
        @include font-size(14px);
    }

    .adt-copy {
        display: none;

        @include media-breakpoint-up('md') {
            display: inline;
        }
    }
}

.footer-widget {
    .p-w-r {
        .pr-sgl_pagination-btn {
            outline: 0;

            @include media-breakpoint-down('sm') {
                flex: 0 1 auto;
                width: auto;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .pr-sgl_window {
            @include media-breakpoint-down('sm') {
                flex: 0 0 294px;
            }
        }
    }

    .widget-container {
        padding: 70px 0 50px;
        background-color: $gray-5;
        text-align: center;

        @include media-breakpoint-up('sm') {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include media-breakpoint-up('lg') {
            padding: 85px 10px 95px;
        }
    }

    .widget-header {
        @include font-size(32px, 40px);
        margin-bottom: 30px;
        font-weight: $font-semibold;

        @include media-breakpoint-up('lg') {
            @include font-size(40px);
            margin-bottom: 40px;
        }
    }

    .widget-content {
        margin: 0 auto;
        overflow-x: auto;

        @include media-breakpoint-up('xl') {
            justify-content: center;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

body[data-action="Club-Landing"] {
    footer {
        .footer-widget {
            display: none;
        }
    }
}

.footer-newsletter {
    .footer-email-signup-title {
        @include font-size(32px);
        margin: 0 0 25px;
        font-weight: $font-semibold;
        text-align: center;
        text-transform: uppercase;
        color: $dark-3;

        @include media-breakpoint-up('lg') {
            margin-top: 15px;
        }
    }

    .footer-email-signup-form {
        display: flex;
        margin-bottom: 13px;

        @include media-breakpoint-up('lg') {
            margin-bottom: 8px;
        }

        .button {
            min-height: 45px;
            padding: 5px 14px;
            border: 0;
            @include font-size(16px, 20px);
            flex: 0 0 auto;
            font-weight: $font-bold;
            background-color: $pink;

            @include media-breakpoint-up('sm') {
                min-height: 50px;
                padding: 5px 22px;
                @include font-size(20px, 24px);
            }
        }

        .textfield {
            flex: 1 1 auto;
            min-width: 0;
            height: 45px;
            border-width: 1px;
            background: $gray-2;
            border-color: $gray-3;
            @include font-size(16px, 21px);
            padding: 11px 12px;
            transition: background $hover, border-color $hover;
            @include placeholder($gray-3);
            font-family: $secondary-font-family;
            border-right: 0;

            @include media-breakpoint-up('sm') {
                height: 50px;
                @include font-size(16px, 24px);
                padding: 12px 16px;
            }

            &:focus {
                background: $white;
            }
        }
    }

    .email-description {
        @include font-size(16px, 32px);
        margin-bottom: 38px;
        font-family: $serif;
        font-weight: $font-medium;
        text-align: center;
        color: $dark-2;

        @include media-breakpoint-up(lg) {
            margin-bottom: 45px;
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}

.page-footer {
    &:not(.simple-footer) {
        .simple-footer-only {
            display: none;
        }
    }
}
