.breadcrumbs {
    margin-bottom: 14px;

    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
    }
}

.breadcrumbs-item {
    flex: 0 0 auto;
}

.breadcrumbs-list {
    margin: -2px 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;

    .text-center & {
        justify-content: center;
    }

    > li {
        margin: 2px 0;
        flex: 0 0 auto;
        @include font-size(14px,18px);
        color: $breadcrumbs-dark;
        text-transform: uppercase;

        > a {
            color: $breadcrumbs-dark;
            transition: color $hover;
            text-decoration: none;

            &:hover {
                color: $breadcrumbs-lite;
            }
        }
    }

    .breadcrumbs-current {
        color: $breadcrumbs-dark;
    }
}
