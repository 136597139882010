/* Moments Sans-400normal - latin */
@font-face {
    font-family: 'Moments Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('../fonts/MomentsSans.woff2') format('woff2'),
}


/* Moments Sans-700normal - latin */
@font-face {
    font-family: 'Moments Sans';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('../fonts/MomentsSansBold.woff2') format('woff2'),
}
