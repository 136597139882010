.slick-dots {
    margin: -5px 0;
    padding: 20px 0 0;
    list-style-type: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    > li {
        flex: 0 0 auto;
        margin: 5px;

        > button {
            margin: 0;
            padding: 0;
            outline: 0;
            cursor: pointer;
            border: 0;
            border-radius: 50%;
            background: none;
            -webkit-appearance: none;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            font-size: 0;
            line-height: 0;
            text-indent: -999px;
            width: 20px;
            height: 20px;

            &:hover {
                &::before {
                    background: $primary-900;
                }
            }

            &::before {
                width: 10px;
                height: 10px;
                content: '';
                display: block;
                border-radius: 50%;
                background: $gray-4;
                transition: background $hover;
            }
        }
    }

    .slick-active {
        > button {
            &::before {
                background: $primary-900;
            }
        }
    }
}

.slick-next,
.slick-prev {
    position: absolute;
    margin: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
    border: 0;
    background: none;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    line-height: 0;
    text-indent: -999px;
    overflow: hidden;

    &.slick-disabled {
        cursor: default;
        opacity: 0.25;
    }

    &::before {
        border: solid $primary-900;
        display: block;
        width: 10px;
        height: 10px;
        content: '';
    }
}

.slick-next {
    right: 0;

    &::before {
        border-width: 0 3px 3px 0;
        transform: translateX(3px) rotate(-45deg);
    }
}

.slick-prev {
    left: 0;

    &::before {
        border-width: 3px 0 0 3px;
        transform: translateX(6px) rotate(-45deg);
    }
}
