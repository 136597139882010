//general styles
.p-w-r * {
    font-family: $sans-serif !important;
}

.p-w-r .pr-star-v4-100-filled {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDguMDA4ODFDMjAuMSA3LjUwODgxIDE5LjcgNi45MDg4MSAxOS4yIDYuOTA4ODFMMTMuNSA2LjEwODgxTDEwLjkgMC45MDg4MTNDMTAuOCAwLjcwODgxMyAxMC43IDAuNjA4ODEzIDEwLjUgMC41MDg4MTNDMTAgMC4yMDg4MTMgOS40IDAuNDA4ODEzIDkuMSAwLjkwODgxM0w2LjYgNi4xMDg4MUwwLjkgNi45MDg4MUMwLjYgNi45MDg4MSAwLjQgNy4wMDg4MSAwLjMgNy4yMDg4MUMtMC4xIDcuNjA4ODEgLTAuMSA4LjIwODgxIDAuMyA4LjYwODgxTDQuNCAxMi42MDg4TDMuNCAxOC4zMDg4QzMuNCAxOC41MDg4IDMuNCAxOC43MDg4IDMuNSAxOC45MDg4QzMuOCAxOS40MDg4IDQuNCAxOS42MDg4IDQuOSAxOS4zMDg4TDEwIDE2LjYwODhMMTUuMSAxOS4zMDg4QzE1LjIgMTkuNDA4OCAxNS40IDE5LjQwODggMTUuNiAxOS40MDg4QzE1LjcgMTkuNDA4OCAxNS43IDE5LjQwODggMTUuOCAxOS40MDg4QzE2LjMgMTkuMzA4OCAxNi43IDE4LjgwODggMTYuNiAxOC4yMDg4TDE1LjYgMTIuNTA4OEwxOS43IDguNTA4ODFDMTkuOSA4LjQwODgxIDIwIDguMjA4ODEgMjAgOC4wMDg4MVoiIGZpbGw9IiNERjExMEIiLz4KPC9zdmc+Cg==") !important;
}

.p-w-r .pr-star-v4-50-filled {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDguMDA4ODFDMjAuMSA3LjUwODgxIDE5LjcgNi45MDg4MSAxOS4yIDYuOTA4ODFMMTMuNSA2LjEwODgxTDEwLjkgMC45MDg4MTNDMTAuOCAwLjcwODgxMyAxMC43IDAuNjA4ODEzIDEwLjUgMC41MDg4MTNDMTAgMC4yMDg4MTMgOS40IDAuNDA4ODEzIDkuMSAwLjkwODgxM0w2LjYgNi4xMDg4MUwwLjkgNi45MDg4MUMwLjYgNi45MDg4MSAwLjQgNy4wMDg4MSAwLjMgNy4yMDg4MUMtMC4xIDcuNjA4ODEgLTAuMSA4LjIwODgxIDAuMyA4LjYwODgxTDQuNCAxMi42MDg4TDMuNCAxOC4zMDg4QzMuNCAxOC41MDg4IDMuNCAxOC43MDg4IDMuNSAxOC45MDg4QzMuOCAxOS40MDg4IDQuNCAxOS42MDg4IDQuOSAxOS4zMDg4TDEwIDE2LjYwODhMMTUuMSAxOS4zMDg4QzE1LjIgMTkuNDA4OCAxNS40IDE5LjQwODggMTUuNiAxOS40MDg4QzE1LjcgMTkuNDA4OCAxNS43IDE5LjQwODggMTUuOCAxOS40MDg4QzE2LjMgMTkuMzA4OCAxNi43IDE4LjgwODggMTYuNiAxOC4yMDg4TDE1LjYgMTIuNTA4OEwxOS43IDguNTA4ODFDMTkuOSA4LjQwODgxIDIwIDguMjA4ODEgMjAgOC4wMDg4MVpNMTMuOCAxMS41MDg4QzEzLjYgMTEuNzA4OCAxMy41IDEyLjEwODggMTMuNSAxMi40MDg4TDE0LjIgMTYuNjA4OEwxMC40IDE0LjYwODhDMTAuMyAxNC41MDg4IDEwLjEgMTQuNTA4OCA5LjkgMTQuNTA4OFYzLjYwODgxTDExLjggNy40MDg4MUMxMS45IDcuNzA4ODEgMTIuMiA3LjkwODgxIDEyLjYgNy45MDg4MUwxNi44IDguNTA4ODFMMTMuOCAxMS41MDg4WiIgZmlsbD0iI0RGMTEwQiIvPgo8L3N2Zz4K") !important;
}

.p-w-r .pr-star-v4-25-filled {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDguMDA4ODFDMjAuMSA3LjUwODgxIDE5LjcgNi45MDg4MSAxOS4yIDYuOTA4ODFMMTMuNSA2LjEwODgxTDEwLjkgMC45MDg4MTNDMTAuOCAwLjcwODgxMyAxMC43IDAuNjA4ODEzIDEwLjUgMC41MDg4MTNDMTAgMC4yMDg4MTMgOS40IDAuNDA4ODEzIDkuMSAwLjkwODgxM0w2LjYgNi4xMDg4MUwwLjkgNi45MDg4MUMwLjYgNi45MDg4MSAwLjQgNy4wMDg4MSAwLjMgNy4yMDg4MUMtMC4xIDcuNjA4ODEgLTAuMSA4LjIwODgxIDAuMyA4LjYwODgxTDQuNCAxMi42MDg4TDMuNCAxOC4zMDg4QzMuNCAxOC41MDg4IDMuNCAxOC43MDg4IDMuNSAxOC45MDg4QzMuOCAxOS40MDg4IDQuNCAxOS42MDg4IDQuOSAxOS4zMDg4TDEwIDE2LjYwODhMMTUuMSAxOS4zMDg4QzE1LjIgMTkuNDA4OCAxNS40IDE5LjQwODggMTUuNiAxOS40MDg4QzE1LjcgMTkuNDA4OCAxNS43IDE5LjQwODggMTUuOCAxOS40MDg4QzE2LjMgMTkuMzA4OCAxNi43IDE4LjgwODggMTYuNiAxOC4yMDg4TDE1LjYgMTIuNTA4OEwxOS43IDguNTA4ODFDMTkuOSA4LjQwODgxIDIwIDguMjA4ODEgMjAgOC4wMDg4MVpNMTMuOCAxMS41MDg4QzEzLjYgMTEuNzA4OCAxMy41IDEyLjEwODggMTMuNSAxMi40MDg4TDE0LjIgMTYuNjA4OEwxMC40IDE0LjYwODhDMTAuMyAxNC41MDg4IDEwLjEgMTQuNTA4OCA5LjkgMTQuNTA4OFYzLjYwODgxTDExLjggNy40MDg4MUMxMS45IDcuNzA4ODEgMTIuMiA3LjkwODgxIDEyLjYgNy45MDg4MUwxNi44IDguNTA4ODFMMTMuOCAxMS41MDg4WiIgZmlsbD0iI0RGMTEwQiIvPgo8L3N2Zz4K") !important;
}

.p-w-r .pr-star-v4-75-filled {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDguMDA4ODFDMjAuMSA3LjUwODgxIDE5LjcgNi45MDg4MSAxOS4yIDYuOTA4ODFMMTMuNSA2LjEwODgxTDEwLjkgMC45MDg4MTNDMTAuOCAwLjcwODgxMyAxMC43IDAuNjA4ODEzIDEwLjUgMC41MDg4MTNDMTAgMC4yMDg4MTMgOS40IDAuNDA4ODEzIDkuMSAwLjkwODgxM0w2LjYgNi4xMDg4MUwwLjkgNi45MDg4MUMwLjYgNi45MDg4MSAwLjQgNy4wMDg4MSAwLjMgNy4yMDg4MUMtMC4xIDcuNjA4ODEgLTAuMSA4LjIwODgxIDAuMyA4LjYwODgxTDQuNCAxMi42MDg4TDMuNCAxOC4zMDg4QzMuNCAxOC41MDg4IDMuNCAxOC43MDg4IDMuNSAxOC45MDg4QzMuOCAxOS40MDg4IDQuNCAxOS42MDg4IDQuOSAxOS4zMDg4TDEwIDE2LjYwODhMMTUuMSAxOS4zMDg4QzE1LjIgMTkuNDA4OCAxNS40IDE5LjQwODggMTUuNiAxOS40MDg4QzE1LjcgMTkuNDA4OCAxNS43IDE5LjQwODggMTUuOCAxOS40MDg4QzE2LjMgMTkuMzA4OCAxNi43IDE4LjgwODggMTYuNiAxOC4yMDg4TDE1LjYgMTIuNTA4OEwxOS43IDguNTA4ODFDMTkuOSA4LjQwODgxIDIwIDguMjA4ODEgMjAgOC4wMDg4MVpNMTMuOCAxMS41MDg4QzEzLjYgMTEuNzA4OCAxMy41IDEyLjEwODggMTMuNSAxMi40MDg4TDE0LjIgMTYuNjA4OEwxMC40IDE0LjYwODhDMTAuMyAxNC41MDg4IDEwLjEgMTQuNTA4OCA5LjkgMTQuNTA4OFYzLjYwODgxTDExLjggNy40MDg4MUMxMS45IDcuNzA4ODEgMTIuMiA3LjkwODgxIDEyLjYgNy45MDg4MUwxNi44IDguNTA4ODFMMTMuOCAxMS41MDg4WiIgZmlsbD0iI0RGMTEwQiIvPgo8L3N2Zz4K") !important;
}

.p-w-r .pr-star-v4-0-filled {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjM2MSA3LjA5OTExTDE4LjkxNDEgNy44Nzg0OUwxNC45MDE3IDExLjc5M0wxNC41MjM4IDEyLjE2MTdMMTQuNjE1IDEyLjY4MTZMMTUuNjE0IDE4LjM3NTVDMTUuNjE2NyAxOC4zOTM0IDE1LjYxNDkgMTguNDAyOCAxNS42MTQxIDE4LjQwNjNDMTUuNjEzOSAxOC40MDcyIDE1LjYxMzcgMTguNDA4IDE1LjYxMzQgMTguNDA4OEgxNS42MTI5SDE1LjYxMjRIMTUuNjExOEgxNS42MTEzSDE1LjYxMDdIMTUuNjEwMkgxNS42MDk2SDE1LjYwOTFIMTUuNjA4NUgxNS42MDhIMTUuNjA3NEgxNS42MDY5SDE1LjYwNjNIMTUuNjA1N0gxNS42MDUySDE1LjYwNDZIMTUuNjA0SDE1LjYwMzVIMTUuNjAyOUgxNS42MDIzSDE1LjYwMTdIMTUuNjAxMkgxNS42MDA2SDE1LjZDMTUuNTc1MyAxOC40MDg4IDE1LjU1NDggMTguNDA4OCAxNS41MzcgMTguNDA4N0wxMC40Njc5IDE1LjcyNUwxMCAxNS40NzczTDkuNTMyMTEgMTUuNzI1TDQuNDMyMTEgMTguNDI1TDQuNDA4NDYgMTguNDM3NUw0LjQwMTU5IDE4LjQ0MTdDNC40MDE0MSAxOC40MzczIDQuNDAxMjMgMTguNDMyNiA0LjQwMTA3IDE4LjQyNzZDNC40MDA3NCAxOC40MTY4IDQuNDAwNTEgMTguNDA1OCA0LjQwMDM1IDE4LjM5MzlMNS4zODQ5NiAxMi43ODE2TDUuNDc2MTggMTIuMjYxN0w1LjA5ODMyIDExLjg5M0wxLjAxNDM2IDcuOTA4NjdMMS4wMjE0NiA3LjkwMTU3TDEuMDM4OTkgNy44OTkxMUw2LjczODk5IDcuMDk5MTFMNy4yNjkyNCA3LjAyNDY4TDcuNTAxMjUgNi41NDIxMUw5Ljk3MzI0IDEuNDAwMzdDOS45ODMyIDEuMzg3NzkgOS45OTIyOCAxLjM3OTY1IDkuOTk5MiAxLjM3NDUzTDEwLjAxODQgMS4zODYwNkwxMC4wMjEzIDEuMzg3NUwxMi42MDU2IDYuNTU2MDNMMTIuODQwNiA3LjAyNjA3TDEzLjM2MSA3LjA5OTExWk0wLjg4MDg0MyA3LjkwOTU3QzAuODgwODMgNy45MDk1NiAwLjg4MTQ2NSA3LjkwOTQ3IDAuODgyNzg4IDcuOTA5MzZMMC44ODA4NDMgNy45MDk1N1oiIHN0cm9rZT0iI0RGMTEwQiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=") !important;
}

//product tile
.product-tile {
    .p-w-r {
        margin: 0;
        height: 20px;
        overflow: hidden;

        .pr-no-reviews {
            display: none;
        }

        .pr-snippet {
            div,
            span {
                vertical-align: top;
            }
        }
    }

    .p-w-r .pr-category-snippet {
        margin: 0;
        display: flex;
        align-items: flex-start;

        .pr-category-snippet__total {
            font-size: 0;
            line-height: 0;
            overflow: hidden;
            text-indent: -999px;
            margin-top: 0;

            &::before {
                display: inline-block;
                vertical-align: top;
                content: attr(content);
                @include font-size(14px, 20px);
                text-indent: 999px;
            }
        }
    }

    .p-w-r .pr-category-snippet__total {
        display: none !important;

        @include media-breakpoint-up(lg) {
            display: block !important;
            margin-top: 3px;
        }
    }

    .p-w-r .pr-snippet-rating-decimal {
        display: none !important;
        @include font-size(14px);
        margin-left: 8px;
        padding: 0 5px;
        font-weight: $font-medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.1px;
        text-align: left;
        color: $gray-16;
        border: none;
        position: relative;

        &::before {
            content: '(';
            position: absolute;
            left: 0;
        }

        &::after {
            content: ')';
            position: absolute;
            right: 0;
        }
    }
}

//top pdp widget
.product-detail {
    #pr-questionsnippet {
        display: none;
    }

    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-reco {
        display: none !important;
    }

    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-container {
        display: inline-block;
    }

    #pr-reviewsnippet {
        margin: 10px 0 0 !important;

        @include media-breakpoint-up(lg) {
            margin: 17px 0 0 !important;
        }
    }

    .p-w-r .pr-snippet .pr-snippet-stars-png .pr-snippet-rating-decimal {
        display: none !important;
        @include font-size(14px);
        margin-left: 8px;
        padding: 0 5px;
        font-weight: $font-medium;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 0.1px;
        text-align: left;
        color: $gray-16;
        border: none;
        position: relative;

        &::before {
            content: '(';
            position: absolute;
            left: 0;
        }

        &::after {
            content: ')';
            position: absolute;
            right: 0;
        }
    }

    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write a {
        margin: 0;
    }

    .pwr-pdp .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write a,
    .pwr-pdp .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write span {
        @include font-size(16px);
        text-decoration: underline;
        padding: 0 0 0 10px;
        margin: 0 0 0 7px !important;
        text-transform: uppercase;
        font-weight: $font-medium;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $dark-2;
        display: block;
        position: relative;

        &.pr-snippet-review-count {
            margin-left: 0 !important;
        }

        @include media-breakpoint-up(lg) {
            padding: 2px 0 0 10px;
        }

        &::before {
            content: "|";
            position: absolute;
            left: 0;
        }
    }
}

//bottom pdp container
.pwr-container {
    #pr-reviewdisplay {
        margin: 60px 0;

        .p-w-r {
            margin: 0;
        }

        .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-header .pr-review-snapshot-header-intro {
            justify-content: center;
            padding: 0;
            margin: 0;
            border: none;

            .pr-headline {
                @include font-size(30px);
                padding: 0;
                font-weight: $font-semibold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $primary-900;
                text-transform: uppercase;
            }

            .pr-subheadline {
                display: none;
            }
        }

        .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block-recommend {
            display: none;
        }

        .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block-histogram {
            display: none;
        }

        .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-header {
            padding: 0;
            margin: 0 0 10px;

            @include media-breakpoint-up(lg) {
                margin: 0 0 23px;
            }
        }

        .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-simple {
            margin: 0;
            justify-content: center;
        }

        .p-w-r .pr-review-snapshot.pr-review-enhanced .pr-review-snapshot-block-container .pr-review-snapshot-block {
            padding: 0;
            border: none;
        }

        .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets-headline {
            @include font-size(64px);
            font-weight: $font-semibold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $primary-900;
            margin: 0;
            display: block;
        }

        .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-review-count {
            @include font-size(20px);
            margin: 18px 0;
            font-weight: $font-medium;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $grayscale-700;
        }

        .p-w-r .pr-review-display .pr-rd-main-header,
        .p-w-r .pr-read-review .pr-rd-main-header {
            display: none;
        }

        .p-w-r .pr-review-snapshot {
            margin-bottom: 80px;
        }

        .p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-snippet-rating-decimal {
            display: none !important;
        }

        .pr-snippet-stars-container {
            text-align: center;
        }
    }

    .p-w-r .pr-subscript {
        margin-bottom: 20px;
    }

    .p-w-r .pr-qa-display {
        padding: 0;
    }

    .p-w-r .pr-qa-display.pr-qa-display-desktop .pr-qa-display-headline {
        margin: 0;
    }

    .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-flag-review-container .pr-rd-flag-review-btn,
    .p-w-r .pr-review-display .pr-rd-pagination .pr-rd-pagination-btn,
    .p-w-r .pr-read-review .pr-rd-pagination .pr-rd-pagination-btn,
    .p-w-r .pr-review-display a,
    .p-w-r .pr-read-review a,
    .p-w-r .pr-accordion .pr-accordion-btn span,
    .p-w-r .pr-qa-display .pr-btn-answer span,
    .p-w-r .pr-qa-display .pr-btn-ask-question span,
    .p-w-r button {
        outline: none;
        color: $primary-900;
        border: none;
        background: transparent;
    }

    .p-w-r .pr-accordion .pr-accordion-btn .pr-caret-icon__line {
        stroke: $primary-900;
    }

    .p-w-r .pr-accessible-btn,
    .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a,
    .p-w-r .pr-accessible-btn.pr-btn-default {
        @include font-size(14px);
        padding: 12px 16px;
        border: solid 1px $pink;
        background: $pink;
        text-transform: uppercase;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        width: auto !important;

        &:hover {
            border: solid 1px $pink;
            background: $pink;
            color: $white;
        }

        @include media-breakpoint-up(lg) {
            @include font-size(20px);
            border: solid 1px $black;
            padding: 16px 20px;
            background: $white;
            color: $primary-900;

            &:hover {
                border: solid 1px $black;
                background: $white;
                color: $primary-900;
            }
        }
    }

    .p-w-r .pr-review-snapshot.pr-snapshot-mobile .pr-review-snapshot-simple .pr-snippet-read-and-write {
        text-align: center;
    }

    .p-w-r .pr-qa-display .pr-qa-display-sort {
        display: none;
    }

    .pr-qa-display-question,
    .pr-qa-display-ask-question {
        padding: 0 0 0 15px;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
}

.p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write {
    display: inline-flex !important;
    margin-top: 3px !important;

    .pr-snippet-review-count {
        &::before {
            display: none;
        }
    }
}

.page {
    .p-w-r {
        .pr-snippet {
            align-items: center;
        }

        .pr-rating-stars {
            display: flex;
        }
    }
}

// stylelint-disable-next-line
@media screen and (max-width: 550px) {
    .p-w-r .pr-media-card-media > picture {
        width: 100%;
        height: 100%;
        max-height: none;
    }
}

// QA
.qanda {
    .p-w-r .pr-qa-display.pr-qa-display-desktop .pr-qa-display-question {
        float: none;
        width: 100%;
    }

    .p-w-r .pr-qa-display.pr-qa-display-desktop .pr-qa-display-item {
        margin-bottom: 5px;
    }

    .p-w-r .pr-qa-display.pr-qa-display-desktop .pr-qa-display-item-details {
        position: static;
    }

    .p-w-r .pr-qa-display .pr-subscript {
        bottom: 0;
    }

    .p-w-r .pr-qa-display .pr-btn-answer .pr-cross-icon__line,
    .p-w-r .pr-qa-display .pr-btn-answer .pr-cross-icon__circle,
    .p-w-r .pr-qa-display .pr-btn-ask-question .pr-cross-icon__line,
    .p-w-r .pr-qa-display .pr-btn-ask-question .pr-cross-icon__circle {
        stroke: $pink;
    }

    .p-w-r .pr-qa-display.pr-qa-display-desktop .pr-qa-display-answer-by-container.pr-qa-display-answer-by-container-expert,
    .p-w-r .pr-qa-display.pr-qa-display-tablet .pr-qa-display-answer-by-container.pr-qa-display-answer-by-container-expert {
        border-color: $pink;
    }

    .p-w-r .pr-qa-display .pr-qa-display-answer .pr-qa-display-answer-icon svg {
        g {
            fill: $pink;
        }
    }

    .p-w-r a {
        color: $pink;

        &:hover {
            color: $pink;
        }
    }

    .p-w-r .pr-helpful-btn:hover span,
    .p-w-r .pr-helpful-btn:hover span {
        color: $pink;
    }

    .p-w-r .pr-helpful-btn:hover .pr-thumbs-cuff-fill,
    .p-w-r .pr-helpful-btn:hover .pr-thumbs-fill {
        fill: $pink;
    }
}
