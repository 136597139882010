.checkbox {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    margin: 0;
    min-height: 20px;

    > input {
        position: absolute;
        left: -9999px;

        &[type="radio"] {
            + span {
                &::after,
                &::before {
                    border-radius: 50%;
                }
            }
        }

        &:checked {
            + span {
                color: $primary-900;

                &::after {
                    opacity: 1;
                }

                &::before {
                    border-color: $primary-900;
                }
            }
        }

        &:disabled {
            + span {
                color: $grayscale-300;

                &::before {
                    border-color: $grayscale-300;
                }

                &::after {
                    background: $grayscale-300;
                }
            }
        }
    }

    > span {
        display: block;
        padding-left: 32px;
        position: relative;
        @include font-size(14px,20px);
        color: $grayscale-600;
        transition: color $hover;

        &::after {
            background: $primary-900;
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            position: absolute;
            left: 5px;
            top: 5px;
            content: '';
            z-index: 2;
            opacity: 0;
            transition: opacity $hover;
        }

        &::before {
            background: $white;
            border: 2px solid $grayscale-300;
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            z-index: 1;
            transition: border-color $hover;
        }
    }
}
