.lobster101-custom-dropdown {
    padding: 20px;

    @include media-breakpoint-up('lg') {
        flex: 1 0 100%;
        max-width: 100%;
        padding: 0;
    }

    > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        @include media-breakpoint-up('lg') {
            margin: -20px;
            display: flex;
            flex-wrap: wrap;
        }

        > li {
            @include media-breakpoint-up('lg') {
                flex: 0 0 25%;
                max-width: 25%;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
            }

            + li {
                margin-top: 20px;

                @include media-breakpoint-up('lg') {
                    margin: 0;
                }
            }

            > a {
                display: flex;
                align-items: center;
                @include font-size(12px, 18px);
                font-weight: $font-semibold;
                text-transform: uppercase;
                color: $grayscale-900;
                font-family: $sans-serif;
                text-decoration: none;

                @include media-breakpoint-up('xsm') {
                    @include font-size(14px);
                }

                @include media-breakpoint-up('lg') {
                    @include font-size(13px);
                    display: inline-block;
                    vertical-align: top;
                }

                @include media-breakpoint-between(lg, xl) {
                    font-size: calculatevw(14px, 1800px);
                }

                @include media-breakpoint-up('xl') {
                    @include font-size(14px);
                }

                &:hover {
                    color: $primary-400;
                }

                > img {
                    display: block;
                    flex: 0 0 60px;
                    max-width: 60px;
                    margin-right: 20px;

                    @include media-breakpoint-up('xsm') {
                        flex: 0 0 100px;
                        max-width: 100px;
                    }

                    @include media-breakpoint-up('lg') {
                        margin: 0 auto 12px;
                        max-width: 100%;
                    }

                    @include media-breakpoint-up('bxl') {
                        margin: 0 auto 16px;
                    }
                }

                > span {
                    display: block;
                    flex: 1 1 auto;
                    min-width: 0;
                }
            }
        }
    }
}
