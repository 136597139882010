h2 {
    &.modal-title {
        @include font-size(26px, 32px);
    }
}

.modal {
    box-sizing: border-box;
    .modal-body,
    .modal-header {
        padding: 20px;
        @include media-breakpoint-up(lg) {
            padding: 30px;
        }
    }
}

.modal-body {
    &.delete-confirmation-body {
        .payment-to-remove,
        .product-to-remove {
            margin-top: 20px;
            font-weight: $font-semibold;
            font-family: $base-font-family;
        }
    }

    p {
        margin: 0;
        
        + p {
            margin-top: 20px;

        }

        &#warning_message {
            margin-top: 0;
        }
    }
}

.modal-footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    
    @include media-breakpoint-up(lg) {
        padding: 20px;
    }

    .button {
        flex: 0 1 auto;
        margin: 10px;
    }
}

.modal-footer-row {
	flex: 1 0 100%;
	max-width: 100%;
	
	.button-row {
		justify-content: space-between;
	
		.button {
			min-width: 0;
			margin: 0;
		}
	}
}

