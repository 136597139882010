.tooltip {
    font-family: $base-font-family;

    .arrow {
        display: none;
    }

    .tooltip-inner {
        background: $primary-900;
        @include font-size(12px, 16px);
    }
}

.tooltip-icon {
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid $gray-6;
    outline: 0;
    background: 0;
    -webkit-appearance: none;
    transform: translateY(-2px);

    &::before {
        display: block;
        text-align: center;
        @include font-size(12px, 14px);
        font-weight: $font-medium;
        color: $gray-6;
        content: 'i';
    }
}
