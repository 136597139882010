.go-to-top {
    margin: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    border: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    background: $primary-400;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all $hover;

    &.is-visible {
        opacity: 0.5;
        visibility: visible;
        z-index: 100;

        &:hover {
            background: $primary-600;
            opacity: 1;
        }

        &:active {
            background: $primary-700;
        }
    }

    body[data-action="Product-Show"] & {
        bottom: 76px;

        @include media-breakpoint-up(lg) {
            bottom: 20px;
        }
    }

    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        content: '';
        width: 16px;
        height: 16px;
        border: solid $white;
        display: block;
        border-width: 3px 3px 0 0;
        margin-top: 5px;
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}
