.print-save {
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .recep-container & {
            margin: 30px 0;
        }
    }
}
