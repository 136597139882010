@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('../fonts/Roboto-Regular.ttf') format('truetype'),
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local(''),
         url('../fonts/Roboto-Italic.ttf') format('truetype'),
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('../fonts/Roboto-Bold.ttf') format('truetype'),
}
