/* stylelint-disable */
/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-800 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/montserrat-v25-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-800.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/montserrat-v25-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/montserrat-v25-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-500italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/montserrat-v25-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-600italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/montserrat-v25-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-700italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-800italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/montserrat-v25-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/montserrat-v25-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v25-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v25-latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v25-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v25-latin-800italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
