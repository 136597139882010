@import "../product/detail";
@import "../product/lgDetail";

.quick-view-dialog {
    max-width: 100%;
    width: 100%;
    margin: 15px 0;

    @include media-breakpoint-up (lg) {
        margin: 72px 0;
    }
    
    &.product-swap-dialog {
    	h1 {
    		padding-left: 20px;
    		padding-right: 20px;
    	}
    	    
    	.card-deck {
    		margin: 0;
    	}
    	
    	.grid-products-wrapper {
    		align-items: stretch;
    		
    		.grid-tile {
    			display: flex;
    			align-items: stretch;
    		}
    		
    		.product {
    			display: flex;
    			flex: 1 0 100%;
    			align-items: stretch;
    		}
    		
    		.product-tile {
    			display: flex;
    			flex: 1 0 100%;
    			flex-direction: column;
    			width: 100%;
    			
    			.image-container,
    			.product-swap-button-wrapper {
	    			flex: 0 0 auto;
	    			width: 100%;
	    		}
	    		
	    		.pdp-link {
	    			@include media-breakpoint-up (md) {
	                    height: auto;
	                    min-height: 44px;
	                }
	    		}
	    		
	    		.tile-body {
	    			flex: 1 1 auto;
	    			width: 100%;
	    		}
    		}
    		
    		
    		
   		}
    	
  		.modal-content {
	        .modal-body {
	            .prices-add-to-cart-actions {
	                position: static;
	            }
	        }
	    }
	    	
		.price-wrapper {
			margin-bottom: 20px;
		}
	    
	   .product-detail {
			&.product-quickview {
				padding-bottom: 0;
			}
			
			.prices-add-to-cart-actions {
				margin-top: 30px;
				.simple-quantity {
					width: 160px;
				}
			}
		}
  	}

    .modal-content {
        max-width: calc(100% - 40px);
        width: 1278px;
        margin: 0 auto;

        .modal-header {
            padding: 0;
            border: none;

            .modal-header-text {
                @include font-size(24px);
                font-weight: $font-semibold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $black;
                padding: 16px 0 16px 10px;
                width: 100%;

                @include media-breakpoint-up (lg) {
                    @include font-size(36px);
                    padding: 40px 0 40px 54px;
                }
            }

            .close {
                padding: 15px 10px;
                margin: 0 0 0 auto;
                opacity: 1;
            }
        }

        .modal-body {
            padding: 0;

            @include media-breakpoint-up (lg) {
                padding: 0 40px 50px;
            }

            .pdp-slider-wrap {
                padding: 0 15px;

                @include media-breakpoint-up (lg) {
                    padding: 0;
                }
            }

            .prices-add-to-cart-actions {
                position: fixed;
                bottom: 0;
                right: 35px;
                left: 35px;

                @include media-breakpoint-up (lg) {
                    position: static;
                }
            }
        }
    }

    .show-product-link {
        margin-top: 25px;
    }
}

.product-detail {
    &.product-quickview {
        margin: 0;
        padding-bottom: 70px;

        @include media-breakpoint-up (lg) {
            padding-bottom: 0;
        }
    }

    .p-w-r {
        &,
        .pr-category-snippet {
            margin: 0;
        }
    }

    .product-name {
        margin-bottom: 13px;
    }
}
