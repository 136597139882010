.maincontent {
    #talkable-offer {
        @include media-breakpoint-up('lg') {
            padding-top: var(--stickyHeaderHeight);
        }
    }
}

body {
    &.modal-opened {
        #talkable-offer {
            z-index: -1 !important;
            position: relative;

            @include media-breakpoint-up('lg') {
                z-index: inherit !important;
            }
        }
    }

    &[data-action="Product-Show"] {
        #talkable-offer {
            z-index: -1 !important;
            position: relative;

            @include media-breakpoint-up('lg') {
                z-index: inherit !important;
            }
        }
    }
}
