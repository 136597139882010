$breakpoint-name: 'sm';
$breakpoint-name: 'sm' !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            max-width: 100%;

            &.in {
                min-width: 50%;
                left: 0;
                margin-right: 56px;
            }
        }
    }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: $white;
    color: $menu-link;
}

.js-main-menu.menu-toggleable-left {

    @include media-breakpoint-down('md') {
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {
    .close-menu {
        padding: 6px 10px 6px 8px;
        min-height: 64px;
        background-color: $close-menu-bg;
        border-bottom: 1px solid $gray-4;
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-up('lg') {
            display: none;
        }

        .back-mob-nav-btn,
        .close-mob-nav-btn {
            flex: 0 0 auto;
            margin: 0 4px 0 0;
            width: 36px;
            height: 36px;
            padding: 0;
            outline: 0;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                display: block;
                flex: 0 0 auto;
            }
        }

        .back-mob-nav-btn {
            margin-right: 4px;
        }

        .mob-category-item-title {
            @include font-size(16px, 24px);
            text-transform: uppercase;
            font-weight: $font-semibold;
            flex: 1 1 auto;
            min-width: 0;
        }
    }

    .menu-group {
        flex: 0 0 100%;
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down('md') {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
        }

        @include media-breakpoint-down('md') {
            .dropdown {
                display: block;
                position: static;
            }

            .show > .dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
            }
        }
    }
}

////

.primary-cat-menu {
    padding: 0;

    .nav-item {
        &.seafood {
            background-color: $primary-900;

            a {
                &:hover {
                    background-color: $primary-900;
                }
            }
        }

        a {
            &:hover {
                background-color: inherit;
            }
        }
    }
}

.brand-logo-link {
    display: none;

    @include media-breakpoint-up('lg') {
        display: block;
        flex: 0 0 176px;
        max-width: 176px;
    }

    @include media-breakpoint-between(lg, xl) {
        flex: 0 0 calculatevw(137px, 1800px);
        max-width: calculatevw(137px, 1800px);
        margin-left: calculatevw(-10px, 1800px);
    }

    img {
        height: 90px;
        display: block;

        @include media-breakpoint-between(lg, xl) {
            height: calculatevw(70px, 1800px);
        }
    }

    .lobster-logo-wrapper {
        background-color: transparent;
        width: auto;
    }
}

.brand-mob-logo-link {
    display: none;
}

.lobster-logo-wrapper {
    width: 110px;
    height: 45px;
    // width: 124px;
    // height: 40px;
    background-color: $white;
    display: flex;
}

.back-mob-nav-btn,
.close-mob-nav-btn {
    border: 0;
    background: none;
}

.menu-toggleable-left {
    .close-menu {
        background-color: $white;
    }

    li > .close-menu {
        display: flex;
    }
}

.categories-menu-wrapper {
    flex: 1 1 auto;
    min-width: 0;
    display: flex;
    align-items: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: auto;
    }
}

.categories-menu-container {

    @include media-breakpoint-up('lg') {
        position: relative;
    }

    .menu-toggleable-left {
        &.categories-menu-content {
            background-color: $white;
            z-index: 4;
            height: 100vh;
            overflow: auto;
            @include media-breakpoint-up('lg') {
                flex: 1 1 auto;
                min-width: 0;
                height: auto;
                background-color: inherit;
                z-index: auto;
                overflow: visible;
            }
        }

        .close-menu {
            @include media-breakpoint-up('md') {
                display: flex;
            }

            @include media-breakpoint-up('lg') {
                display: none;
            }
        }
    }

    .nav-item {
        > .nav-link {
            @include media-breakpoint-up('lg') {
                @include font-size(13px);
                font-weight: $font-semibold;
                text-transform: uppercase;
                color: $grayscale-900;
                text-decoration: none;
                padding: 0 12px;

                &::after {
                    display: none;
                }
            }

            @include media-breakpoint-between(lg, xl) {
                font-size: calculatevw(14px, 1800px);
                padding: 0 calculatevw(12px, 1800px);
            }
        }
    }

    .desktop-nav-lobstergram {
        .nav-item {
            > .nav-link {
                @include media-breakpoint-up('lg') {
                    padding: 0 32px;
                }

                @include media-breakpoint-between(lg, xl) {
                    padding: 0 calculatevw(32px, 1800px);
                }
            }
        }
    }

    .navbar {
        padding: 0;
        display: block;

        @include media-breakpoint-up('lg') {
            position: unset;
        }

        > .close-menu {
            > .back {
                display: none;
            }

            > .brand-mob-logo-link {
                display: inline-block;
            }

            > .mob-category-item-title {
                display: none;
            }
        }
    }
}

.mob-nav {
    margin: 0;
    height: 100vh;
    flex: 1 1 100%;
    max-width: 100%;

    &.navbar-nav {
        flex-wrap: nowrap;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .dropdoen-menu {
        overflow: auto;
    }

    .dropdown-toggle {
        display: block;
        position: relative;
        white-space: normal;

        &::after {
            display: block;
            content: '';
            position: absolute;
            right: 22px;
            top: 50%;
            transform: translateY(-50%);
            border: 0;
            height: 14px;
            width: 8px;
            background-image: url('../images/icons/arrow.svg');
            background-repeat: no-repeat;
        }
    }

    .dropdown-item {
        border-bottom: 1px solid $gray-4;
        padding: 0;
        white-space: normal;

        &:hover,
        &:active {
            background: none;
        }

        &.top-category {
            padding: 9px 20px;
            background: $gray-4;
            border: 0;
            display: flex;
            justify-content: center;

            .dropdown-link,
            .nav-link {
                border: 2px solid $dark-3;
                padding: 8px 10px;
                text-align: center;
                flex: 1 1 100%;
                max-width: 100%;
                box-sizing: border-box;
                background: none;

                &:hover {
                    border-color: $primary-400;
                }
            }
        }

        .dropdown-item {
            &:not(.top-category) {
                text-transform: none;
                color: $dark-2;

                .dropdown-link {
                    font-weight: $font-medium;
                    text-transform: none;
                }
            }
        }
    }

    .nav-item {
        border-bottom: 1px solid $gray-4;
    }

    .dropdown-link,
    .nav-link {
        display: block;
        padding: 18px 52px 18px 13px;
        @include font-size(16px, 24px);
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $font-semibold;
        color: $dark-3;

        &:hover,
        &:active {
            background: none;
            color: $primary-400;
        }
    }
}

.desktop-nav {
    display: none;
    height: 100px;
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-between(lg, xl) {
        height: calculatevw(100px, 1800px);
    }

    @include media-breakpoint-up('lg') {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;

        &::-webkit-scrollbar {
            display: none;
        }

        > li {
            flex: 0 0 auto;
            display: flex;
            align-items: stretch;

            > .nav-link {
                flex: 0 0 auto;
                display: flex;
                align-items: center;

                &:hover {
                    position: relative;

                    &::after {
                        position: absolute;
                        left: 0;
                        top: 99%;
                        height: 5px;
                        width: 100%;
                        content: '';
                        background: $white;
                    }
                }
            }
        }
    }

    .megaflyout {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        border: 0;
        border-radius: 0;
        width: 100%;
        padding: 37px 30px;
        float: none;
        z-index: 1000;
        transform: translateX(-50%);
        flex-direction: row-reverse;

        @include media-breakpoint-between(lg, xl) {
            padding: calculatevw(37px, 1800px) calculatevw(30px, 1800px);
        }

        &.show {
            display: flex;
        }

        .dropdown-menu-list {
            margin-top: 16px;

            @include media-breakpoint-between(lg, xl) {
                margin-top: calculatevw(16px, 1800px);
            }

            &:first-child {
                margin-top: 0;
            }
        }

        .dropdown-toggle {
            white-space: normal;
        }

        .dropdown-item {
            padding: 0 20px 10px 0;
            width: auto;
            white-space: normal;

            @include media-breakpoint-between(lg, xl) {
                padding: 0 calculatevw(20px, 1800px) calculatevw(10px, 1800px) 0;
            }

            &.level_2 {
                padding: 0 10px;
                width: auto;
                box-sizing: border-box;
                @include font-size(16px, 24px);
                margin-bottom: 16px;

                @include media-breakpoint-between(lg, xl) {
                    padding: 0 calculatevw(10px, 1800px);
                    font-size: calculatevw(16px, 1800px);
                    line-height: calculatevw(24px, 1800px);
                    margin-bottom: calculatevw(16px, 1800px);
                }

                &:last-child {
                    margin: 0;
                }

                > .dropdown-link {
                    font-weight: $font-semibold;
                }

                .unclickable {
                    pointer-events: none;
                }
            }

            .dropdown-link {
                text-decoration: none;
                text-transform: uppercase;
            }

            &.level_3 {
                @include font-size(14px, 24px);
                padding: 0;
                margin-bottom: 4px;

                @include media-breakpoint-between(lg, xl) {
                    font-size: calculatevw(14px, 1800px);
                    line-height: calculatevw(24px, 1800px);
                    margin-bottom: calculatevw(4px, 1800px);
                }

                &:last-child {
                    margin: 0;
                }

                .dropdown-link {
                    @include media-breakpoint-up('lg') {
                        text-transform: none;
                    }
                }
            }

            &:hover,
            &:active {
                background: inherit;
            }
        }

        .megaflyout-nav {
            flex: 1 1 auto;
            min-width: 0;
            display: flex;
            align-content: flex-start;
            align-items: flex-start;
            overflow: hidden;

            .megaflyout-nav-complex {
                flex: 1 1 100%;
                max-width: 100%;

                > .dropdown-menu-list {
                    display: flex;
                    align-items: flex-start;
                    align-content: flex-start;
                    flex-wrap: wrap;
                    margin: 0 -10px;
                    @include media-breakpoint-between(lg, xl) {
                        margin: 0 calculatevw(-10px, 1800px);
                    }

                    > li {
                        flex: 0 0 25%;
                        max-width: 25%;
                        padding: 0 10px;

                        @include media-breakpoint-between(lg, xl) {
                            padding: 0 calculatevw(10px, 1800px);
                        }
                    }

                    .dropdown-menu-list {
                        margin: 16px 0 0;

                        @include media-breakpoint-between(lg, xl) {
                            margin-top: calculatevw(16px, 1800px);
                        }
                    }
                }
            }
        }

        .megaflyout-nav-complex {
            &[data-children="1"] {
                flex: 0 0 25%;
                max-width: 25%;

                > .dropdown-menu-list {
                    > li {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

            &[data-children="2"] {
                flex: 0 0 50%;
                max-width: 50%;

                > .dropdown-menu-list {
                    > li {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }

            &[data-children="3"] {
                flex: 0 0 75%;
                max-width: 75%;

                > .dropdown-menu-list {
                    > li {
                        flex: 0 0 33.3333%;
                        max-width: 33.3333%;
                    }
                }
            }
        }

        .megaflyout-nav-simple {
            flex: 1 1 auto;
            min-width: 0;
            display: flex;

            > .dropdown-menu-list {
                margin: 0 -10px;
                flex: 1;

                @include media-breakpoint-between(lg, xl) {
                    margin: 0 calculatevw(-10px, 1800px);
                }

                > li {
                    padding: 0 10px;

                    @include media-breakpoint-between(lg, xl) {
                        padding: 0 calculatevw(10px, 1800px);
                    }
                }

                .dropdown-menu-list {
                    margin: 16px 0 0;

                    @include media-breakpoint-between(lg, xl) {
                        margin-top: calculatevw(16px, 1800px);
                    }
                }
            }
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }

    > .dropdown {
        @include media-breakpoint-up('lg') {
            position: unset;
        }
    }
}

.nav-category-content-wrapper {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
        flex: 0 0 auto;
        overflow: hidden;
    }

    .nav-category-content {
        position: relative;
        color: $white;

        .nav-banner-content {
            position: absolute;
            left: 0;
            bottom: 0;
            line-height: normal;
            width: 100%;
            max-height: 100%;
            padding: 24px;
            overflow: hidden;

            @include media-breakpoint-between(lg, xl) {
                padding: calculatevw(24px, 1800px);
            }
        }

        .nav-link-wrapper,
        .nav-text {
            line-height: normal;
        }

        .nav-text {
            @include font-size(24px);
            font-weight: $font-semibold;
            text-transform: uppercase;

            @include media-breakpoint-between(lg, xl) {
                font-size: calculatevw(24px, 1800px);
            }

            &.heavy {
                @include font-size(32px);
                font-weight: $font-extrabold;

                @include media-breakpoint-between(lg, xl) {
                    font-size: calculatevw(32px, 1800px);
                }
            }
        }

        .nav-banner-link {
            @include font-size(16px);
            display: inline-block;
            box-sizing: border-box;
            margin-top: 10px;
            border: 2px solid $white;
            padding: 10px 15px;
            font-weight: $font-bold;
            color: $white;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color $hover, border-color $hover, color $hover;

            @include media-breakpoint-between(lg, xl) {
                font-size: calculatevw(16px, 1800px);
                margin-top: calculatevw(10px, 1800px);
                border-width: calculatevw(2px, 1800px);
                padding: calculatevw(10px, 1800px) calculatevw(15px, 1800px);
            }

            &:hover {
                background-color: $primary-900;
                border-color: $primary-900;
                color: $white;
            }
        }
    }

    .nav-banner-img {
        display: block;
        box-sizing: border-box;
        width: 420px;
        height: 300px;
        object-fit: cover;

        @include media-breakpoint-between(lg, xl) {
            width: calculatevw(420px, 1800px);
            height: calculatevw(300px, 1800px);
        }
    }
}

.js-top-header-menu-hide {
    &.top-header-menu {
        position: absolute;
        width: 100vw;
        left: -9999px;
        z-index: -1;

        + .site-header {
            top: 0;
        }
    }
}
