.select {
    -webkit-border-radius: 0;
    border: 3px solid $gray-4;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin: 0;
    outline: 0;
    padding: 0 30px 0 15px;
    @include font-size(20px,24px);
    height: 56px;
    font-family: $base-font-family;
    font-weight: $font-medium;
    color: $primary-900;
    appearance: none;
    background: $white url('../images/icons/select.svg') right 10px center/12px 8px no-repeat;
    transition: border-color $hover;

    &::-ms-expand {
        display: none;
    }

    &:focus {
        border-color: $grayscale-600;
    }

    &.form-control {
        &:focus {
            background: $white;
            outline: 0;
            box-shadow: none;
        }
    }

    &.is-invalid {
        border-color: $primary-500;
    }

    &.select-micro {
        height: 36px;
        padding-left: 10px;
        border-width: 1px;
        @include font-size(14px,18px);
    }

    &.select-small {
        height: 44px;
        @include font-size(16px,20px);
        padding-left: 12px;
        border-width: 2px;
    }
}
