.brand-lobstergram {
    .product-detail {
        .product-name {
            font-weight: $font-bold;
            font-family: $momentsSans;
            @include font-size(50px);
        }

        .product-sku {
            @include font-size(12px);
            display: block;
            margin-top: 10px;
            margin-bottom: -24px;
        }

        .attributes {
            font-family: $roboto;
        }

        .attribute-select {
            border: 1px solid rgb(220, 221, 223);
            background-color: $white;

            &.selected {
                color: rgb(201, 24, 30);
                border-color: rgb(201, 24, 30);

                &::before {
                    background-color: rgb(201, 24, 30);
                }

                &::after {
                    border-color: rgb(201, 24, 30);
                }

                .price-variation {
                    color: rgb(201, 24, 30);
                }
            }
        }

        .add-to-cart {
            background-color: rgb(201, 24, 30);
            border-color: rgb(201, 24, 30);

            &:disabled {
                border-color: $grayscale-200;
                background: $grayscale-200;
            }
        }
    }

    .product-details-wrapper {
        border: 0.0625rem solid rgb(233, 234, 236);
        padding: 1.25rem;
        background: rgb(245, 245, 245);
    }

    .details-tabs {
        border: 0.0625rem solid rgb(233, 234, 236);
        background: rgb(245, 245, 245);
        padding: 20px 30px;
    }

    .variation-attributes-wrapper {
        &.two-variants-product {
            .attribute {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                flex-wrap: wrap;
            }

            .attribute-label {
                margin: 10px auto -15px;
            }

            .attribute-text {
                max-width: 58px;
            }

            .numberPeople,
            .lobsterWeight {
                .price-variation {
                    display: none;
                }
            }

            .attribute-select {
                padding: 0 10px;
                min-height: 68px;
                @include font-size(16px);

                &::before,
                &::after {
                    display: none;
                }
            }

            .attribute-text {
                text-align: center;
            }
        }

        &.redemption-product {
            .price-variation {
                display: none;
            }
        }
    }

    .lg-redemption-bottom-msg {
        margin-top: 20px;
    }

    .variation-attribute-redemption-lg {
        margin: 15px 0 0;
        border: 1px solid rgb(233, 234, 236);
        border-radius: 3px;
        padding: 15px 0;

        .attribute-label {
            font-weight: $font-bold;
        }

        .attribute-select {
            @include font-size(14px);
            padding: 5px 5px 5px 35px;
            border: 0;
            background-color: transparent;

            &::before {
                left: 4px;
                z-index: 1;
            }

            &::after {
                left: 0;
                background-color: $white;
            }
        }
    }
                                    
}
