@import "../variables";
@import "../components/functions";
@import "../components/mixins";

.lobstergram-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;

    .page-breadcrumb {
        ul {
            justify-content: center;
        }
    }
}

@media (min-width: $lg-xsmall) {
    .lobstergram-container {
        max-width: 540px;
    }
}

@media (min-width: $lg-small) {
    .lobstergram-container {
        max-width: 720px;
    }
}

@media (min-width: $lg-middle) {
    .lobstergram-container {
        max-width: 960px;
    }
}

@media (min-width: $lg-large) {
    .lobstergram-container {
        max-width: 1140px;
    }
}

.lobstergram-content {
    font-family: $roboto;
    -webkit-font-smoothing: antialiased;

    a {
        color: $lg-link;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    h1 {
        font-size: 2.25rem;
        font-family: $rokkitt;
        margin: 30px 0;
        font-weight: bold;
        line-height: 1.5;
    }

    h2 {
        font-size: 1.85rem;
        font-family: $rokkitt;
        margin: 25px 0;
        font-weight: bold;
        line-height: 1.5;
    }

    h3 {
        font-size: 1.5rem;
        font-family: $rokkitt;
        margin: 25px 0;
        font-weight: bold;
        line-height: 1.5;
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    p {
        line-height: 2;
        margin: 16px 0;
        font-family: $roboto;
    }

    ol:not(.breadcrumbs-list) {
        margin: 16px 0;
        padding: 0 0 0 40px;
        font-size: 1rem;
        line-height: 2;
    }

    ul:not(.breadcrumbs-list):not(.blog-navigation-list):not(.blog-navigation-sub-list) {
        margin: 16px 0;
        padding: 0 0 0 40px;
        list-style-type: disc;
        font-size: 1rem;
        line-height: 2;
    }

    .breadcrumbs {
        font-family: $sans-serif;
    }

    .wp-caption {
        font-size: 12px;
        line-height: 16px;
        font-style: italic;

        img {
            display: block;
            margin-bottom: 12px;
        }
    }
}

.lobstergram-youtube {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        border: 0;
    }
}
