.pagination {
    margin: -5px;
    padding: 30px 0;
    list-style-type: none;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    > li {
        flex: 0 0 auto;
        margin: 5px;
        @include font-size(16px, 20px);
    }

    .active {
        .page {
            text-decoration: none;
            font-weight: bold;
        }
    }

    .page {
        background: none;
    }
}