h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $base-font-family;
    font-weight: $font-medium;
}

h1 {
    @include font-size(40px, 50px);
}

h2 {
    @include font-size(36px, 46px);
}

h3 {
    @include font-size(24px, 30px);
}

h4 {
    @include font-size(20px, 26px);
}
