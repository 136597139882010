.minicart {
    position: relative;

    .hide-no-link {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .hide-link-med {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .popover {
        top: 99%;
        left: auto;
        right: 0;
        min-width: 353px;
        display: none;
        border-radius: 0;
        border-color: $grayscale-100;
        background: $white;
        font-family: $base-font-family;
        max-height: 80vh;
        overflow: auto;

        &.show {
            display: block;
        }

        p {
            font-family: $base-font-family;
        }
    }

    .minicart-close-icon {
        @include font-size(26px, 16px);
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cart {
        padding: 20px 20px 0;
    }

    .item-attributes {
        flex: 1 1 auto;
        min-width: 0;
    }

    .line-item-name {
        @include font-size(14px, 20px);
        margin: -2px 0 6px;
        display: block;
        color: $primary-400;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $font-semibold;
    }

    .minicart-line-item-quantity {
        @include font-size(12px, 16px);
        margin-bottom: 6px;
        color: $primary-900;
        text-align: right;
    }

    .minicart-product-item {
        border: 0;

        + .minicart-product-item {
            margin-top: 16px;
        }
    }

    .minicart-product-card {
        border: 0;
        border-bottom: 1px solid $grayscale-100;
        border-radius: 0;
        padding: 0 0 16px;
        background: none;
        position: static;
        margin: 0;

        .line-item-header {
            padding-right: 10px;
        }
    }

    .card-body {
        padding: 0;
    }

    .item-image {
        margin-right: 12px;
        width: 75px;
    }

    .line-item-attributes {
        @include font-size(12px, 16px);
        margin: 0 0 6px;
        color: $gray-12;
    }

    .price {
        @include font-size(12px, 16px);
        margin: 0;
        color: $primary-900;
        text-align: right;

        .strike-through {
            margin: 0;
        }
    }

    .line-item-instock-date {
        margin-bottom: 10px;
    }

    .line-item-promo {
        @include font-size(12px, 16px);
        margin-top: 6px;
        color: $primary-400;
    }

    .product-availability {
        margin-bottom: 0;

        &.in-stock {
            display: none;

            + .line-item-instock-date {
                display: none;
            }
        }
    }

    .estimated-total {
        padding: 16px 0;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        color: $dark-3;

        .sub-total {
            @include font-size(16px, 20px);
            margin: 0;
            color: $grayscale-900;
            font-weight: $font-medium;
        }
    }

    .sub-total-label {
        @include font-size(14px, 20px);
        margin-bottom: 0;
        margin-right: 5px;
    }

    .checkout-continue {
        margin: 0;
        background: none;
        border: 0;
        padding: 0;
    }

    .cart-show-btn {
        @include font-size(14px);
    }
}

.non-adjusted-price {
    display: none;
}
