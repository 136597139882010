// TODO cleanup styles from 'base' sfra

.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.6;
    width: 100%;
    top: 0;
    left: 0;
}

.hide-order-discount,
.hide-shipping-discount {
    display: none;
}

.totals-row {
    &.hide-order-discount,
    &.hide-shipping-discount {
        display: none;
    }
}

.skip {
    position: absolute;
    left: 0;
    top: -68px;
    overflow: hidden;
    padding: 16px 24px;
    background: $white;
    transition: all 0.2s ease-in-out;
}

// end of default sfra styles

.breadcrumb {
    @include font-size(14px);
    padding: 0;
    margin: 14px 0;
    color: $gray-9;
    text-transform: uppercase;

    .breadcrumb-item {
        &:last-child {
            color: $gray-8;
        }

        &:first-child {
            &::before {
                display: none;
            }
        }

        &::before {
            content: '|';
            color: $gray-9;
        }

        a {
            text-decoration: none;
        }
    }
}

/* -----------Homepage horizontal scroll fix--------------- */

.pd-container {
    > .row {
        margin: 0;

        > .col-12 {
            padding: 0;
        }
    }
}

.page-designer-reference {
    .pd-slick-slider-wrapper {
        max-width: 100%;
        overflow: hidden;
    }
}

.homepage-4-items-row {
    .items-4row-description,
    .items-4row-header {
        padding: 0 20px;
    }

    .items-4row-header {
        line-height: 40px;
    }
}

/* -----------Hide Trusted Site icon on Mobile PDP--------------- */

[data-action="Product-Show"] #trustedsite-tm-image {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.truevault-polaris-privacy-notice {
    display: block;
}

.contact-us-landing-page {
    .truevault-polaris-privacy-notice {
        text-align: center;
        margin-top: 20px;
    }
}

.sr-only {
    position: absolute !important;
}
