@font-face {
    font-family: 'Rokkitt';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../fonts/Rokkitt.woff2') format('woff2');
}

@font-face {
    font-family: 'Rokkitt';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../fonts/RokkittBold.woff2') format('woff2');
}
