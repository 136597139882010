.info-box {
    margin-left: -16px;
    margin-right: -16px;
    border-top: 1px solid $grayscale-100;
    border-bottom: 1px solid $grayscale-100;
    @include media-breakpoint-up(lg) {
        margin-left: -24px;
        margin-right: -24px;
    }

    &.info-box-bottom {
        margin-bottom: -24px;
        border-bottom: 0;
    }

    &.info-box-top {
        margin-top: -24px;
        border-top: 0;
    }
}

.info-box-row {
    display: flex;
    justify-content: space-between;
    @include font-size(12px, 16px);
    padding: 10px 16px;
    @include media-breakpoint-up(lg) {
        padding: 12px 24px;
    }

    &.hide-order-discount,
    &.hide-shipping-discount {
        display: none;
    }

    &.info-box-row-total {
        font-weight: $font-semibold;
        background: rgba($grayscale-100, 0.35);

        &:first-child {
            border-top: 0;
        }

        .info-box-title,
        .info-box-value {
            @include font-size(18px, 24px);
        }
    }

    + .info-box-row {
        border-top: 1px solid $grayscale-100;
    }

    .info-box-title {
        flex: 0 1 auto;
        opacity: 0.9;
        margin-right: 20px;
        @include font-size(16px, 20px);

        .product-summary-block & {
            @include font-size(14px, 18px);
        }

        .shipping-method {
            white-space: normal;
        }
    }

    .info-box-value {
        flex: 0 0 auto;
        @include font-size(16px, 20px);

        .product-summary-block & {
            @include font-size(14px, 18px);
        }
    }

    .price {
        @include font-size(16px, 20px);

        .product-summary-block & {
            @include font-size(14px, 18px);
        }
    }

    .sales {
        font-weight: $font-regular;
    }
}

