.custom-datepicker {
    &.ui-datepicker {
        z-index: 5 !important; // we need to overwrite inline styles from js
        background: $white;
        border: 1px solid $gray-6;
        border-radius: 8px;
        font-family: inherit;

        .ui-state-disabled {
            text-decoration: line-through;
            color: $gray-7;
            opacity: 1;

            span {
                border: 0;
                background: none;
                color: $gray-7;
            }
        }

        .ui-datepicker-header {
            background: none;
            border: 0;
            font-weight: $font-regular;
        }

        .ui-datepicker-prev,
        .ui-datepicker-next {
            cursor: pointer;

            &.ui-state-disabled {
                opacity: 0.3;
            }

            .ui-icon {
                background: none;

                &.ui-state-hover {
                    border: 0;
                }
            }
        }

        .ui-datepicker-prev {
            position: absolute;
            top: 10px;
            width: 14px;
            height: 14px;
            background: url('../../images/sprites.png') no-repeat;
            background-position: -181px -28px;
            left: 10px;

            span {
                display: block;
                position: absolute;
                left: 50%;
                margin-left: -8px;
                top: 50%;
                margin-top: -8px;
            }
        }

        .ui-datepicker-next {
            position: absolute;
            top: 10px;
            width: 14px;
            height: 14px;
            background: url('../../images/sprites.png') no-repeat;
            background-position: -193px -28px;
            right: 10px;

            span {
                display: block;
                position: absolute;
                left: 50%;
                margin-left: -8px;
                top: 50%;
                margin-top: -8px;
            }
        }

        .ui-datepicker-title {
            @include font-size(14px, 28px);
            margin: 0 36px;
            text-align: center;
        }

        table {
            @include font-size(13px);
            width: 100%;
            border-collapse: collapse;
            margin: 0 0 6px;
        }

        th {
            padding: 12px 5px;
            text-align: center;
            font-weight: $font-bold;
            border: 0;
        }

        td {
            border: 0;
            padding: 1px 3px 1px 0;
            text-align: center;

            span {
                display: block;
                padding: 3px;
                text-decoration: none;
                text-align: center;
            }

            a {
                text-decoration: none;
                border: 0;
                background: none;
                color: $primary;
                text-align: center;

                &.ui-state-hover {
                    color: $danger-700;
                }
            }
        }

        .ui-datepicker-buttonpane {
            background-image: none;
            margin: 11px 0 0;
            padding: 0 3px;
            border-left: 0;
            border-right: 0;
            border-bottom: 0;

            button {
                float: right;
                margin: 8px 3px 6px;
                padding: 3px 10px 5px;
                width: auto;
                overflow: visible;
                cursor: pointer;
            }

            button.ui-datepicker-current {
                float: left;
            }
        }

        .discountDate {
            a {
                border: 1px solid $blue;
                color: $blue;
                font-weight: $font-bold;

                &:hover {
                    border: 1px solid $blue;
                    color: $blue;
                    font-weight: $font-bold;
                }
            }
        }
    }
}

.deliverydate-input {
    background: url('../../images/datedrop.png') no-repeat 100% $white;
    cursor: pointer;
}

.deliverydate-message {
    color: $danger-600;
    margin-top: 20px;
}
