.page {
    .simplebar-scrollbar {
        &::before {
            opacity: 1;
            background: $grayscale-600;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .simplebar-track {
        background: $gray-11;

        &.simplebar-vertical {
            width: 6px;
        }
    }
}

