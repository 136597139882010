.collapsible-all {
    .title {
        line-height: 2.5rem; /* 40/16 */

        @include clearfix;

        &::after {
            float: right;
            content: "\f078";
            font-family: "FontAwesome";
        }
    }

    .btn {
        &:focus {
            box-shadow: none;
        }
    }

    .content,
    .card-body {
        display: none;
    }

    &.active {
        .title::after {
            content: "\f077";
            margin-top: -0.125em; /* 2/16 */
        }

        .content,
        .card-body {
            display: block;
        }
    }
}

.container div.collapsible-all button.title {
    color: $black;
    text-decoration: none;
    border: none;
    background-color: transparent;

    &:hover {
        text-decoration: none;
    }
}

@each $size in map-keys($grid-breakpoints) {
    .collapsible-#{$size} {
        .btn {
            &:focus {
                box-shadow: none;
            }
        }
    }
}
