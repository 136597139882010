button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.button {
    display: inline-block;
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    @include font-size(20px, 24px);
    font-weight: $font-semibold;
    text-align: center;
    min-height: 56px;
    font-family: $base-font-family;
    padding: 6px 17px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    border: 3px solid;
    overflow: visible;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    cursor: pointer;
    height: auto;
    background: transparent;
    transition: background $hover, border-color $hover, color $hover;

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        cursor: default;
        transition: none;
    }

    &.button-block {
        width: 100%;
        display: flex;
    }

    &.button-alt {
        color: $white;
        background: $primary-400;
        border-color: $primary-400;

        &:hover {
            background: $primary-600;
            border-color: $primary-600;
            color: $white;
        }

        &:active {
            background: $primary-700;
            border-color: $primary-700;
        }

        &:disabled {
            border-color: $grayscale-200;
            background: $grayscale-200;
        }
    }

    &.button-alt-transparent {
        color: $primary-400;
        background: $white;
        border-color: $primary-400;

        &:hover {
            background: $primary-600;
            border-color: $primary-600;
            color: $white;
        }

        &:active {
            background: $primary-700;
            border-color: $primary-700;
        }

        &:disabled {
            border-color: $grayscale-200;
            background: $white;
            color: $grayscale-200;
        }
    }

    &.button-micro {
        min-height: 36px;
        padding: 4px 12px;
        border-width: 1px;
        @include font-size(14px, 18px);

        &.button-icon {
            padding-right: 16px;
            padding-left: 40px;
            position: relative;

            &::before {
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translateY(-50%);
                content: '';
                width: 24px;
                height: 24px;
            }

            &.button-bookmark {
                &::before {
                    background: url('../images/icons/icon-bookmark-white.svg');
                }
            }

            &.button-print {
                &::before {
                    background: url('../images/icons/icon-print-white.svg');
                }
            }
        }
    }

    &.button-primary {
        color: $primary-900;
        background: $white;
        border-color: $primary-900;

        &:hover {
            background: $primary-400;
            border-color: $primary-400;
            color: $primary-50;
        }

        &:active {
            background: $primary-600;
            border-color: $primary-600;
        }

        &:disabled {
            border-color: $gray;
            background: $white;
            color: $gray;
        }
    }

    &.button-secondary {
        color: $white;
        background: $primary-900;
        border-color: $primary-900;

        &:hover {
            background: $grayscale-600;
            border-color: $grayscale-600;
        }

        &:active {
            background: $grayscale-400;
            border-color: $grayscale-400;
        }

        &:disabled {
            border-color: $grayscale-200;
            background: $grayscale-200;
        }
    }

    &.button-sm-block {
        @include media-breakpoint-down(sm) {
            width: 100%;
            display: flex;
        }
    }

    &.button-small {
        min-height: 44px;
        padding: 5px 14px;
        border-width: 2px;
        @include font-size(16px, 20px);
    }
}

.button-link {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    @include font-size(16px, 20px);
    font-weight: 600;
    text-align: center;
    font-family: $base-font-family;
    padding: 0;
    text-decoration: underline;
    /* stylelint-disable */
    text-underline-offset: 4px;
    /* stylelint-enable */
    text-transform: uppercase;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    border: 0;
    background: none;
    overflow: visible;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    cursor: pointer;
    height: auto;
    transition: color $hover;

    &:hover {
        color: $primary-400;
    }

    &:active {
        color: $primary-600;
    }

    &:disabled {
        cursor: default;
        transition: none;
        text-decoration: none;
        color: $gray;
    }

    &.button-link-small {
        @include font-size(12px, 18px);
    }
}

.button-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -10px -20px;

    .button {
        min-width: 240px;
        max-width: 420px;
    }

    .button-row-item {
        flex: 0 1 auto;
        margin: 10px 20px;
    }
}
